import { Text, styled } from "tamagui";
import { variantEmphasisHash } from "ui/src/components/buttons/Button/components/CustomButtonText/variantEmphasisHash";
import { buttonStyledContext, lineHeights } from "ui/src/components/buttons/Button/constants";
import { getMaybeHexOrRGBColor } from "ui/src/components/buttons/Button/utils/getMaybeHexOrRGBColor";
import { getContrastPassingTextColor } from "ui/src/utils/colors";
export const CustomButtonText = styled(Text, {
  context: buttonStyledContext,
  tag: "span",
  fontFamily: "$button",
  color: "$color",
  cursor: "pointer",
  maxFontSizeMultiplier: 1.2,
  numberOfLines: 1,
  variants: {
    variant: {
      // @ts-expect-error we know variant will be ButtonVariant
      ":string": (variant, { props }) => {
        if (props.isDisabled) {
          return {
            color: "$neutral2"
          };
        }
        const customBackgroundColor = props["custom-background-color"];
        const maybeCustomColorProp = getMaybeHexOrRGBColor(props.color);
        const maybeButtonBackgroundCustomColor = getMaybeHexOrRGBColor(customBackgroundColor);
        if (maybeButtonBackgroundCustomColor) {
          return {
            color: getContrastPassingTextColor(maybeButtonBackgroundCustomColor)
          };
        }
        if (maybeCustomColorProp) {
          return {
            color: maybeCustomColorProp,
            "$group-item-hover": {
              color: maybeCustomColorProp
            }
          };
        }
        const emphasis = (
          // @ts-expect-error we know emphasis will be ButtonEmphasis
          props.emphasis || "primary"
        );
        return variantEmphasisHash[variant][emphasis];
      }
    },
    // these are taken from Figma and mapped to the values in fonts.ts > buttonFont
    // https://github.com/Uniswap/universe/blob/main/packages/ui/src/theme/fonts.ts
    size: {
      xxsmall: {
        fontSize: "$micro",
        fontWeight: "$medium",
        lineHeight: lineHeights.xxsmall
      },
      xsmall: {
        fontSize: "$micro",
        fontWeight: "$medium",
        lineHeight: lineHeights.xsmall
      },
      small: {
        fontSize: "$small",
        fontWeight: "$medium",
        lineHeight: lineHeights.small
      },
      medium: {
        fontSize: "$medium",
        fontWeight: "$medium",
        lineHeight: lineHeights.medium
      },
      large: {
        fontSize: "$large",
        fontWeight: "$medium",
        lineHeight: lineHeights.large
      }
    }
  }
});

import { XStack, styled } from "tamagui";
import { FOCUS_SCALE } from "ui/src/components/buttons/Button/components/CustomButtonFrame/constants";
import { withCommonPressStyle } from "ui/src/components/buttons/Button/components/CustomButtonFrame/utils";
import { variantEmphasisHash } from "ui/src/components/buttons/Button/components/CustomButtonFrame/variantEmphasisHash";
import { buttonStyledContext } from "ui/src/components/buttons/Button/constants";
import { getMaybeHexOrRGBColor } from "ui/src/components/buttons/Button/utils/getMaybeHexOrRGBColor";
import { getHoverCssFilter } from "ui/src/utils/colors";
const CustomButtonFrameWithoutCustomProps = styled(XStack, {
  context: buttonStyledContext,
  name: "Button",
  tag: "button",
  group: "item",
  "$platform-web": {
    containerType: "normal"
  },
  animation: "fast",
  // TODO(WALL-6057): Ideally we'd like to animate everything; however, there's an issue when animating colors with alpha channels
  animateOnly: ["transform"],
  // instead of setting border: 0 when no border, make it 1px but transparent, so the size or alignment of a button won't change unexpectedly between variants
  borderWidth: 1,
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "$transparent",
  borderColor: "$borderColor",
  focusVisibleStyle: {
    outlineWidth: 1,
    outlineOffset: 2,
    outlineStyle: "solid"
  },
  cursor: "pointer",
  height: "auto",
  // `variants` is a Tamagui term that allows us to define variants for the component
  variants: {
    // By default, the button scales up and down in both directions, slightly more in the Y direction
    // The best strategy will depend on the Button' parent's styling
    // These presets are a good starting point; but, feel free to add more as needed!
    focusScaling: {
      default: {
        focusVisibleStyle: {
          scaleX: FOCUS_SCALE,
          scaleY: FOCUS_SCALE - 0.075
        }
      },
      equal: {
        focusVisibleStyle: {
          scaleX: FOCUS_SCALE,
          scaleY: FOCUS_SCALE
        }
      },
      // Scale is proportionate to the button's size
      // On Web, sometimes a smaller button needs to be scaled down more, to account for the `outlineWidth` and `outlineOffset`, so it won't extend beyond the button's bounds in its unfocused state
      // We could try to automatically detect this, but in reality it's based on a few different factors relating to the surrounding elements; so, we'll opt to manually apply this `focusScaling` variant as-needed
      "equal:smaller-button": {
        focusVisibleStyle: {
          scaleX: FOCUS_SCALE - 0.05,
          scaleY: FOCUS_SCALE - 0.05
        }
      },
      "more-x": {
        focusVisibleStyle: {
          scaleX: FOCUS_SCALE - 0.075,
          scaleY: FOCUS_SCALE
        }
      }
    },
    // `variant` refers to ButtonVariantProps['variant']
    variant: {
      // See tamagui docs on string, boolean, and number variants
      // https://arc.net/l/quote/lpoqmiea
      ":string": (untypedVariant, { props }) => {
        const variant = untypedVariant || "default";
        const emphasis = (
          // @ts-expect-error we know emphasis will be ButtonEmphasis
          props.emphasis || "primary"
        );
        if (props.isDisabled) {
          return {
            backgroundColor: "$surface2"
          };
        }
        const maybeHexOrRGBColorFromProps = getMaybeHexOrRGBColor(props.backgroundColor);
        const maybePrimaryColor = getMaybeHexOrRGBColor(props["primary-color"]);
        if (maybeHexOrRGBColorFromProps) {
          const DARK_FILTER = getHoverCssFilter({ isDarkMode: true, differenceFrom1: 0.25 });
          const LIGHT_FILTER = getHoverCssFilter({ isDarkMode: true, differenceFrom1: 0.25 });
          return {
            borderColor: maybeHexOrRGBColorFromProps,
            pressStyle: withCommonPressStyle({
              filter: DARK_FILTER
            }),
            "$theme-dark": {
              focusVisibleStyle: {
                filter: DARK_FILTER,
                outlineColor: maybePrimaryColor ?? maybeHexOrRGBColorFromProps
              },
              hoverStyle: {
                filter: DARK_FILTER,
                borderColor: maybePrimaryColor
              },
              pressStyle: withCommonPressStyle({
                filter: DARK_FILTER
              })
            },
            "$theme-light": {
              focusVisibleStyle: {
                filter: LIGHT_FILTER,
                outlineColor: maybePrimaryColor ?? maybeHexOrRGBColorFromProps
              },
              pressStyle: withCommonPressStyle({
                filter: LIGHT_FILTER
              }),
              hoverStyle: {
                borderColor: maybePrimaryColor ?? maybeHexOrRGBColorFromProps,
                filter: LIGHT_FILTER
              }
            }
          };
        }
        return variantEmphasisHash[variant][emphasis];
      }
    },
    iconPosition: {
      before: {
        flexDirection: "row"
      },
      after: {
        flexDirection: "row-reverse"
      }
    },
    size: {
      xxsmall: {
        px: "$spacing6",
        py: "$spacing4",
        borderRadius: "$rounded12",
        gap: "$spacing4"
      },
      xsmall: {
        px: "$spacing12",
        py: "$spacing8",
        borderRadius: "$rounded12",
        gap: "$spacing4"
      },
      small: {
        px: "$spacing12",
        py: "$spacing8",
        borderRadius: "$rounded12",
        gap: "$spacing8"
      },
      medium: {
        px: "$spacing16",
        py: "$spacing12",
        borderRadius: "$rounded16",
        gap: "$spacing8"
      },
      large: {
        px: "$spacing20",
        py: "$spacing16",
        borderRadius: "$rounded20",
        gap: "$spacing12"
      }
    },
    fill: {
      true: {
        alignSelf: "stretch",
        flex: 1,
        flexBasis: 0
      }
    },
    // TODO(WEB-6347): change variant name back to `disabled`
    isDisabled: {
      true: {
        pointerEvents: "none",
        userSelect: "none"
      }
    },
    emphasis: {
      primary: {},
      secondary: {},
      tertiary: {},
      "text-only": {}
    }
  },
  defaultVariants: {
    variant: "default",
    emphasis: "primary",
    focusScaling: "default",
    fill: true,
    size: "medium"
  }
});
export const CustomButtonFrame = CustomButtonFrameWithoutCustomProps;

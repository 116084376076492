import { Trade as RouterSDKTrade, ZERO_PERCENT } from "@uniswap/router-sdk";
import { Percent, Price } from "@uniswap/sdk-core";
import { V2DutchOrderTrade, PriorityOrderTrade as IPriorityOrderTrade, V3DutchOrderTrade } from "@uniswap/uniswapx-sdk";
import { BigNumber } from "ethers/lib/ethers";
import {
  Routing
} from "uniswap/src/data/tradingApi/__generated__/index";
import { getCurrencyAmount, ValueType } from "uniswap/src/features/tokens/getCurrencyAmount";
import { MAX_AUTO_SLIPPAGE_TOLERANCE } from "uniswap/src/constants/transactions";
import { getSwapFee } from "uniswap/src/features/transactions/swap/types/getSwapFee";
export class UniswapXV2Trade extends V2DutchOrderTrade {
  routing = Routing.DUTCH_V2;
  quote;
  slippageTolerance;
  swapFee;
  indicative = false;
  constructor({
    quote,
    currencyIn,
    currencyOut,
    tradeType
  }) {
    const orderInfo = transformToV2DutchOrderInfo(quote.quote.orderInfo);
    super({ currencyIn, currenciesOut: [currencyOut], orderInfo, tradeType });
    this.quote = quote;
    this.slippageTolerance = this.quote.quote.slippageTolerance ?? 0;
    this.swapFee = getSwapFee(quote);
  }
  get needsWrap() {
    return this.inputAmount.currency.isNative;
  }
  get deadline() {
    return this.order.info.deadline;
  }
  get inputTax() {
    return ZERO_PERCENT;
  }
  get outputTax() {
    return ZERO_PERCENT;
  }
}
export class UniswapXV3Trade extends V3DutchOrderTrade {
  routing = Routing.DUTCH_V3;
  quote;
  slippageTolerance;
  swapFee;
  indicative = false;
  constructor({
    quote,
    currencyIn,
    currencyOut,
    tradeType
  }) {
    const orderInfo = transformToV3DutchOrderInfo(quote.quote.orderInfo);
    const { expectedAmountIn, expectedAmountOut } = quote.quote;
    const expectedAmounts = expectedAmountIn && expectedAmountOut ? { expectedAmountIn, expectedAmountOut } : void 0;
    super({ currencyIn, currenciesOut: [currencyOut], orderInfo, tradeType, expectedAmounts });
    this.quote = quote;
    this.slippageTolerance = this.quote.quote.slippageTolerance ?? 0;
    this.swapFee = getSwapFee(quote);
  }
  get needsWrap() {
    return this.inputAmount.currency.isNative;
  }
  get deadline() {
    return this.order.info.deadline;
  }
  get inputTax() {
    return ZERO_PERCENT;
  }
  get outputTax() {
    return ZERO_PERCENT;
  }
}
export class PriorityOrderTrade extends IPriorityOrderTrade {
  routing = Routing.PRIORITY;
  quote;
  slippageTolerance;
  swapFee;
  indicative = false;
  constructor({
    quote,
    currencyIn,
    currencyOut,
    tradeType
  }) {
    const orderInfo = transformToPriorityOrderInfo(quote.quote.orderInfo);
    const { expectedAmountIn, expectedAmountOut } = quote.quote;
    const expectedAmounts = expectedAmountIn && expectedAmountOut ? { expectedAmountIn, expectedAmountOut } : void 0;
    super({ currencyIn, currenciesOut: [currencyOut], orderInfo, tradeType, expectedAmounts });
    this.quote = quote;
    this.slippageTolerance = this.quote.quote.slippageTolerance ?? 0;
    this.swapFee = getSwapFee(quote);
  }
  get needsWrap() {
    return this.inputAmount.currency.isNative;
  }
  get deadline() {
    return this.order.info.deadline;
  }
  get inputTax() {
    return ZERO_PERCENT;
  }
  get outputTax() {
    return ZERO_PERCENT;
  }
}
export class ClassicTrade extends RouterSDKTrade {
  quote;
  routing = Routing.CLASSIC;
  deadline;
  slippageTolerance;
  swapFee;
  indicative = false;
  constructor({
    quote,
    deadline,
    ...routes
  }) {
    super(routes);
    this.quote = quote;
    this.deadline = deadline;
    this.slippageTolerance = quote?.quote.slippage ?? MAX_AUTO_SLIPPAGE_TOLERANCE;
    this.swapFee = getSwapFee(quote);
  }
  _cachedPriceImpact;
  // Overrides trade sdk price impact with backend price impact when available, as sdk price impact formula can be inaccurate.
  get priceImpact() {
    if (!this._cachedPriceImpact) {
      const quotePriceImpact = this.quote?.quote.priceImpact;
      this._cachedPriceImpact = quotePriceImpact ? new Percent(Math.round(quotePriceImpact * 100), 1e4) : super.priceImpact;
    }
    return this._cachedPriceImpact;
  }
}
export var ApprovalAction = /* @__PURE__ */ ((ApprovalAction2) => {
  ApprovalAction2["None"] = "none";
  ApprovalAction2["Permit2Approve"] = "permit2-approve";
  ApprovalAction2["RevokeAndPermit2Approve"] = "revoke-and-permit2-approve";
  ApprovalAction2["Unknown"] = "unknown";
  return ApprovalAction2;
})(ApprovalAction || {});
function transformToV2DutchOrderInfo(orderInfo) {
  return {
    ...orderInfo,
    nonce: BigNumber.from(orderInfo.nonce),
    additionalValidationContract: orderInfo.additionalValidationContract ?? "",
    additionalValidationData: orderInfo.additionalValidationData ?? "",
    input: {
      token: orderInfo.input.token ?? "",
      startAmount: BigNumber.from(orderInfo.input.startAmount),
      endAmount: BigNumber.from(orderInfo.input.endAmount)
    },
    outputs: orderInfo.outputs.map((output) => ({
      token: output.token ?? "",
      startAmount: BigNumber.from(output.startAmount),
      endAmount: BigNumber.from(output.endAmount),
      recipient: output.recipient
    })),
    cosigner: orderInfo.cosigner ?? ""
  };
}
function transformToV3DutchOrderInfo(orderInfo) {
  return {
    ...orderInfo,
    startingBaseFee: BigNumber.from(0),
    nonce: BigNumber.from(orderInfo.nonce),
    additionalValidationContract: orderInfo.additionalValidationContract ?? "",
    additionalValidationData: orderInfo.additionalValidationData ?? "",
    input: {
      token: orderInfo.input.token ?? "",
      startAmount: BigNumber.from(orderInfo.input.startAmount),
      curve: {
        relativeBlocks: orderInfo.input.curve?.relativeBlocks ?? [],
        relativeAmounts: orderInfo.input.curve?.relativeAmounts?.map((amount) => BigInt(amount)) ?? []
      },
      maxAmount: BigNumber.from(orderInfo.input.maxAmount),
      adjustmentPerGweiBaseFee: BigNumber.from(orderInfo.input.adjustmentPerGweiBaseFee)
    },
    outputs: orderInfo.outputs.map((output) => ({
      token: output.token ?? "",
      startAmount: BigNumber.from(output.startAmount),
      curve: {
        relativeBlocks: orderInfo.input.curve?.relativeBlocks ?? [],
        relativeAmounts: orderInfo.input.curve?.relativeAmounts?.map((amount) => BigInt(amount)) ?? []
      },
      minAmount: BigNumber.from(output.minAmount),
      adjustmentPerGweiBaseFee: BigNumber.from(output.adjustmentPerGweiBaseFee),
      recipient: output.recipient
    })),
    cosigner: orderInfo.cosigner ?? ""
  };
}
function transformToPriorityOrderInfo(orderInfo) {
  return {
    ...orderInfo,
    nonce: BigNumber.from(orderInfo.nonce),
    additionalValidationContract: orderInfo.additionalValidationContract ?? "",
    additionalValidationData: orderInfo.additionalValidationData ?? "",
    input: {
      token: orderInfo.input.token ?? "",
      amount: BigNumber.from(orderInfo.input.amount),
      mpsPerPriorityFeeWei: BigNumber.from(orderInfo.input.mpsPerPriorityFeeWei)
    },
    outputs: orderInfo.outputs.map((output) => ({
      token: output.token ?? "",
      amount: BigNumber.from(output.amount),
      mpsPerPriorityFeeWei: BigNumber.from(output.mpsPerPriorityFeeWei),
      recipient: output.recipient
    })),
    baselinePriorityFeeWei: BigNumber.from(orderInfo.baselinePriorityFeeWei),
    auctionStartBlock: BigNumber.from(orderInfo.auctionStartBlock)
  };
}
export function validateIndicativeQuoteResponse(response) {
  const { input, output } = response;
  if (response.input && response.output && response.requestId && response.type && input.amount && input.chainId && input.token && output.amount && output.chainId && output.token) {
    return { ...response, input: { amount: input.amount, chainId: input.chainId, token: output.token }, output: { amount: output.amount, chainId: output.chainId, token: output.token } };
  }
  return void 0;
}
export class IndicativeTrade {
  quote;
  inputAmount;
  outputAmount;
  executionPrice;
  swapFee;
  inputTax;
  outputTax;
  slippageTolerance;
  indicative = true;
  constructor({ quote, currencyIn, currencyOut, slippageTolerance }) {
    this.quote = quote;
    const inputAmount = getCurrencyAmount({ value: this.quote.input.amount, valueType: ValueType.Raw, currency: currencyIn });
    const outputAmount = getCurrencyAmount({ value: this.quote.output.amount, valueType: ValueType.Raw, currency: currencyOut });
    if (!inputAmount || !outputAmount) {
      throw new Error("Error parsing indicative quote currency amounts");
    }
    this.inputAmount = inputAmount;
    this.outputAmount = outputAmount;
    this.executionPrice = new Price(currencyIn, currencyOut, this.quote.input.amount, this.quote.output.amount);
    this.slippageTolerance = slippageTolerance;
  }
}
export class BridgeTrade {
  quote;
  inputAmount;
  outputAmount;
  executionPrice;
  tradeType;
  routing = Routing.BRIDGE;
  indicative = false;
  swapFee;
  inputTax = ZERO_PERCENT;
  outputTax = ZERO_PERCENT;
  slippageTolerance;
  priceImpact;
  deadline;
  constructor({ quote, currencyIn, currencyOut, tradeType }) {
    this.quote = quote;
    this.swapFee = getSwapFee(quote);
    const quoteInputAmount = quote.quote.input?.amount;
    const quoteOutputAmount = quote.quote.output?.amount;
    if (!quoteInputAmount || !quoteOutputAmount) {
      throw new Error("Error parsing bridge quote currency amounts");
    }
    const inputAmount = getCurrencyAmount({ value: quoteInputAmount, valueType: ValueType.Raw, currency: currencyIn });
    const outputAmount = getCurrencyAmount({ value: quoteOutputAmount, valueType: ValueType.Raw, currency: currencyOut });
    if (!inputAmount || !outputAmount) {
      throw new Error("Error parsing bridge quote currency amounts");
    }
    this.inputAmount = inputAmount;
    this.outputAmount = outputAmount;
    this.executionPrice = new Price(currencyIn, currencyOut, quoteInputAmount, quoteOutputAmount);
    this.tradeType = tradeType;
  }
  /* Bridge trades have no slippage and hence a static execution price.
  The following methods are overridden for compatibility with other trade types */
  worstExecutionPrice(_threshold) {
    return this.executionPrice;
  }
  maximumAmountIn(_slippageTolerance, _amountIn) {
    return this.inputAmount;
  }
  minimumAmountOut(_slippageTolerance, _amountOut) {
    return this.outputAmount;
  }
}

"use strict";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isAtomicBatchingSupported: false
};
const walletCapabilitiesSlice = createSlice({
  name: "walletCapabilities",
  initialState,
  reducers: {
    setIsAtomicBatchingSupported(state, { payload }) {
      state.isAtomicBatchingSupported = payload;
    }
  }
});
export const { setIsAtomicBatchingSupported } = walletCapabilitiesSlice.actions;
export default walletCapabilitiesSlice.reducer;

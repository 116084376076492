"use strict";
import { InterfaceEventName } from "@uniswap/analytics-events";
import styled, { css } from "lib/styled-components";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { ClickableStyle } from "theme/components/styles";
import { sendAnalyticsEvent } from "uniswap/src/features/telemetry/send";
import { anonymizeLink } from "utils/anonymizeLink";
const LinkStyle = css`
  color: ${({ theme }) => theme.accent1};
  stroke: ${({ theme }) => theme.accent1};
  font-weight: 500;
`;
export const StyledInternalLink = styled(Link)`
  ${ClickableStyle}
  ${LinkStyle}
`;
function outboundLink({ label }) {
  sendAnalyticsEvent(InterfaceEventName.EXTERNAL_LINK_CLICK, {
    label
  });
}
function handleClickExternalLink(event) {
  const { target, href } = event.currentTarget;
  const anonymizedHref = anonymizeLink(href);
  if (target === "_blank" || event.ctrlKey || event.metaKey) {
    outboundLink({ label: anonymizedHref });
  } else {
    event.preventDefault();
    outboundLink({ label: anonymizedHref });
  }
}
const StyledLink = styled.a`
  ${ClickableStyle}
  ${LinkStyle}
`;
export function ExternalLink({
  target = "_blank",
  href,
  rel = "noopener noreferrer",
  ...rest
}) {
  const handleClick = useCallback(
    (event) => {
      handleClickExternalLink(event);
      if (rest.onClick) {
        rest.onClick(event);
      }
    },
    [rest]
  );
  return <StyledLink target={target} rel={rel} href={href} onClick={handleClick} {...rest} />;
}

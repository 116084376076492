import { getAddress } from "@ethersproject/address";
import { logger } from "utilities/src/logger/logger";
export function isAddress(value) {
  if (!value) {
    return false;
  }
  try {
    return getAddress(value.toLowerCase());
  } catch {
    return false;
  }
}
export function isSameAddress(a, b) {
  return a === b || a?.toLowerCase() === b?.toLowerCase();
}
export function shortenAddress(address = "", chars = 4, charsEnd) {
  const parsed = isAddress(address);
  if (!parsed) {
    return "";
  }
  if (charsEnd === void 0) {
    charsEnd = chars;
  }
  if (chars <= 0 && charsEnd <= 0) {
    logger.warn("utilities/src/addresses/index.ts", "shortenAddress", "chars and charsEnd must be positive integers");
    chars = 4;
    charsEnd = 4;
  }
  return ellipseAddressAdd0x(parsed, chars, charsEnd);
}
function ellipseAddressAdd0x(targetAddress, charsStart = 4, charsEnd = 4) {
  const hasPrefix = targetAddress.startsWith("0x");
  const prefix = hasPrefix ? "" : "0x";
  const wholeAddress = prefix + targetAddress;
  if (charsStart + charsEnd >= wholeAddress.length) {
    return wholeAddress;
  }
  return ellipseMiddle(prefix + targetAddress, charsStart + 2, charsEnd);
}
export function ellipseMiddle(target, charsStart = 4, charsEnd = 4) {
  return `${target.slice(0, charsStart)}...${target.slice(target.length - charsEnd)}`;
}

import { forwardRef } from "react";
import { withStaticProperties } from "tamagui";
import { useLayoutAnimationOnChange } from "ui/src/animations";
import { CustomButtonFrame } from "ui/src/components/buttons/Button/components/CustomButtonFrame/CustomButtonFrame";
import { CustomButtonText } from "ui/src/components/buttons/Button/components/CustomButtonText/CustomButtonText";
import { ThemedIcon } from "ui/src/components/buttons/Button/components/ThemedIcon";
import { ThemedSpinningLoader } from "ui/src/components/buttons/Button/components/ThemedSpinnerLoader";
import { useIsStringOrTransTag } from "ui/src/components/buttons/Button/hooks/useIsStringOrTransTag";
import { getIconPosition } from "ui/src/components/buttons/Button/utils/getIconPosition";
import { getIsButtonDisabled } from "ui/src/components/buttons/Button/utils/getIsButtonDisabled";
const ButtonComponent = forwardRef(function Button2({
  children,
  icon,
  fill = true,
  shouldAnimateBetweenLoadingStates = true,
  variant = "default",
  focusScaling = "default",
  emphasis = "primary",
  size = "medium",
  loading,
  iconPosition: propIconPosition = "before",
  isDisabled: propDisabled,
  ...props
}, ref) {
  useLayoutAnimationOnChange(shouldAnimateBetweenLoadingStates ? loading : false);
  const isDisabled = getIsButtonDisabled({ isDisabled: propDisabled, loading });
  const iconPosition = getIconPosition(propIconPosition);
  const isStringOrTransTag = useIsStringOrTransTag(children);
  const customBackgroundColor = props.backgroundColor;
  return <CustomButtonFrame
    ref={ref}
    fill={fill}
    focusScaling={focusScaling}
    emphasis={emphasis}
    variant={variant}
    size={size}
    iconPosition={iconPosition}
    isDisabled={isDisabled}
    custom-background-color={customBackgroundColor}
    dd-action-name={props["dd-action-name"] ?? (typeof children === "string" ? children : void 0)}
    {...props}
  ><ThemedIcon
    custom-background-color={customBackgroundColor}
    isDisabled={isDisabled}
    emphasis={emphasis}
    size={size}
    variant={variant}
    typeOfButton="button"
  >{loading ? void 0 : icon}</ThemedIcon>{
    /* `iconPosition` takes care of setting flexDirection: 'row' | 'row-reverse', so we don't need to worry about it here */
  }{loading ? <ThemedSpinningLoader
    isDisabled={isDisabled}
    emphasis={emphasis}
    size={size}
    variant={variant}
    typeOfButton="button"
  /> : null}{isStringOrTransTag ? <CustomButtonText>{children}</CustomButtonText> : children}</CustomButtonFrame>;
});
export const Button = withStaticProperties(ButtonComponent, {
  Text: CustomButtonText,
  Icon: ThemedIcon
});

"use strict";
import { CustomUserProperties, SwapEventName } from "@uniswap/analytics-events";
import {
  SwapRouter,
  UNIVERSAL_ROUTER_ADDRESS,
  UniversalRouterVersion
} from "@uniswap/universal-router-sdk";
import { toHex } from "@uniswap/v3-sdk";
import { useTotalBalancesUsdForAnalytics } from "graphql/data/apollo/useTotalBalancesUsdForAnalytics";
import { useAccount } from "hooks/useAccount";
import { useEthersWeb3Provider } from "hooks/useEthersProvider";
import { useGetTransactionDeadline } from "hooks/useTransactionDeadline";
import useBlockNumber from "lib/hooks/useBlockNumber";
import { formatCommonPropertiesForTrade, formatSwapSignedAnalyticsEventProperties } from "lib/utils/analytics";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMultichainContext } from "state/multichain/useMultichainContext";
import { TradeFillType } from "state/routing/types";
import { useUserSlippageTolerance } from "state/user/hooks";
import { trace } from "tracing/trace";
import { sendAnalyticsEvent } from "uniswap/src/features/telemetry/send";
import i18n from "uniswap/src/i18n";
import { logger } from "utilities/src/logger/logger";
import { useTrace } from "utilities/src/telemetry/trace/TraceContext";
import { calculateGasMargin } from "utils/calculateGasMargin";
import { UserRejectedRequestError, WrongChainError } from "utils/errors";
import isZero from "utils/isZero";
import { didUserReject, swapErrorToUserReadableMessage } from "utils/swapErrorToUserReadableMessage";
import { getWalletMeta } from "utils/walletMeta";
class GasEstimationError extends Error {
  constructor() {
    super(i18n.t("swap.error.expectedToFail"));
  }
}
class ModifiedSwapError extends Error {
  constructor() {
    super(i18n.t("swap.error.modifiedByWallet"));
  }
}
export function useUniversalRouterSwapCallback(trade, fiatValues, options) {
  const { t } = useTranslation();
  const account = useAccount();
  const accountRef = useRef(account);
  accountRef.current = account;
  const { chainId } = useMultichainContext();
  const provider = useEthersWeb3Provider({ chainId });
  const providerRef = useRef(provider);
  providerRef.current = provider;
  const analyticsContext = useTrace();
  const blockNumber = useBlockNumber();
  const getDeadline = useGetTransactionDeadline();
  const isAutoSlippage = useUserSlippageTolerance()[0] === "auto";
  const portfolioBalanceUsd = useTotalBalancesUsdForAnalytics();
  return useCallback(
    () => trace({ name: "Swap (Classic)", op: "swap.classic" }, async (trace2) => {
      try {
        const account2 = accountRef.current;
        const provider2 = providerRef.current;
        if (account2.status !== "connected") {
          throw new Error("wallet not connected");
        }
        if (!provider2) {
          throw new Error("missing provider");
        }
        if (!trade) {
          throw new Error("missing trade");
        }
        const connectedChainId = await provider2.getSigner().getChainId();
        if (account2.chainId !== connectedChainId || account2.chainId !== chainId) {
          throw new WrongChainError();
        }
        const deadline = await getDeadline();
        const { calldata: data, value } = SwapRouter.swapCallParameters(trade, {
          slippageTolerance: options.slippageTolerance,
          deadlineOrPreviousBlockhash: deadline?.toString(),
          inputTokenPermit: options.permit,
          fee: options.feeOptions,
          flatFee: options.flatFeeOptions
        });
        const tx = {
          from: account2.address,
          to: UNIVERSAL_ROUTER_ADDRESS(UniversalRouterVersion.V1_2, chainId),
          data,
          // TODO(https://github.com/Uniswap/universal-router-sdk/issues/113): universal-router-sdk returns a non-hexlified value.
          ...value && !isZero(value) ? { value: toHex(value) } : {}
        };
        let gasLimit;
        try {
          const gasEstimate = await provider2.estimateGas(tx);
          gasLimit = calculateGasMargin(gasEstimate);
        } catch (gasError) {
          sendAnalyticsEvent(SwapEventName.SWAP_ESTIMATE_GAS_CALL_FAILED, {
            ...formatCommonPropertiesForTrade(trade, options.slippageTolerance),
            ...analyticsContext,
            client_block_number: blockNumber,
            txRequest: tx,
            isAutoSlippage
          });
          const wrappedError = new Error("gas error", { cause: gasError });
          logger.warn("useUniversalRouter", "useUniversalRouterSwapCallback", "Failed to estimate gas", wrappedError);
          throw new GasEstimationError();
        }
        const response = await trace2.child({ name: "Send transaction", op: "wallet.send_transaction" }, async () => {
          try {
            const provider3 = providerRef.current;
            if (!provider3) {
              throw new Error("missing provider");
            }
            return await provider3.getSigner().sendTransaction({ ...tx, gasLimit });
          } catch (error) {
            if (didUserReject(error)) {
              throw new UserRejectedRequestError(swapErrorToUserReadableMessage(t, error));
            } else {
              throw error;
            }
          }
        });
        sendAnalyticsEvent(SwapEventName.SWAP_SIGNED, {
          ...formatSwapSignedAnalyticsEventProperties({
            trade,
            timeToSignSinceRequestMs: trace2.now(),
            allowedSlippage: options.slippageTolerance,
            fiatValues,
            txHash: response.hash,
            portfolioBalanceUsd,
            trace: analyticsContext
          }),
          // TODO (WEB-2993): remove these after debugging missing user properties.
          [CustomUserProperties.WALLET_ADDRESS]: account2.address,
          [CustomUserProperties.WALLET_TYPE]: account2.connector.name,
          [CustomUserProperties.PEER_WALLET_AGENT]: provider2 ? getWalletMeta(provider2)?.agent : void 0
        });
        if (tx.data !== response.data) {
          sendAnalyticsEvent(SwapEventName.SWAP_MODIFIED_IN_WALLET, {
            txHash: response.hash,
            expected: tx.data,
            actual: response.data,
            ...analyticsContext
          });
          if (!response.data || response.data.length === 0 || response.data === "0x") {
            throw new ModifiedSwapError();
          }
        }
        return { type: TradeFillType.Classic, response, deadline };
      } catch (error) {
        if (error instanceof GasEstimationError) {
          throw error;
        } else if (error instanceof UserRejectedRequestError) {
          throw error;
        } else if (error instanceof ModifiedSwapError) {
          throw error;
        } else {
          throw Error(swapErrorToUserReadableMessage(t, error));
        }
      }
    }),
    [
      trade,
      t,
      chainId,
      getDeadline,
      options.slippageTolerance,
      options.permit,
      options.feeOptions,
      options.flatFeeOptions,
      fiatValues,
      portfolioBalanceUsd,
      analyticsContext,
      blockNumber,
      isAutoSlippage
    ]
  );
}

"use strict";
import { NATIVE_CHAIN_ID } from "constants/tokens";
import { useCurrency } from "hooks/Tokens";
import { useCallback, useEffect, useMemo } from "react";
import { useMultichainContext } from "state/multichain/useMultichainContext";
import { useSwapAndLimitContext, useSwapContext } from "state/swap/useSwapContext";
import { getNativeAddress } from "uniswap/src/constants/addresses";
import { useUrlContext } from "uniswap/src/contexts/UrlContext";
import { getChainInfo } from "uniswap/src/features/chains/chainInfo";
import { useEnabledChains } from "uniswap/src/features/chains/hooks/useEnabledChains";
import { useSupportedChainId } from "uniswap/src/features/chains/hooks/useSupportedChainId";
import { UniverseChainId } from "uniswap/src/features/chains/types";
import { CurrencyField } from "uniswap/src/types/currency";
import { isAddress } from "utilities/src/addresses";
import { getParsedChainId } from "utils/chainParams";
export function useSwapActionHandlers() {
  const { swapState, setSwapState } = useSwapContext();
  const { setCurrencyState } = useSwapAndLimitContext();
  const onSwitchTokens = useCallback(
    ({
      newOutputHasTax,
      previouslyEstimatedOutput
    }) => {
      if (newOutputHasTax && swapState.independentField === CurrencyField.INPUT) {
        setSwapState((swapState2) => ({
          ...swapState2,
          typedValue: previouslyEstimatedOutput
        }));
      } else {
        setSwapState((prev) => ({
          ...prev,
          independentField: prev.independentField === CurrencyField.INPUT ? CurrencyField.OUTPUT : CurrencyField.INPUT
        }));
      }
      setCurrencyState((prev) => ({
        inputCurrency: prev.outputCurrency,
        outputCurrency: prev.inputCurrency
      }));
    },
    [setCurrencyState, setSwapState, swapState.independentField]
  );
  return {
    onSwitchTokens
  };
}
function parseFromURLParameter(urlParam) {
  if (typeof urlParam === "string") {
    return urlParam;
  }
  return void 0;
}
export function parseCurrencyFromURLParameter(urlParam) {
  if (typeof urlParam === "string") {
    const valid = isAddress(urlParam);
    if (valid) {
      return valid;
    }
    const upper = urlParam.toUpperCase();
    if (upper === "ETH") {
      return "ETH";
    }
    if (urlParam === NATIVE_CHAIN_ID) {
      return NATIVE_CHAIN_ID;
    }
  }
  return void 0;
}
function createBaseSwapURLParams({
  chainId,
  outputChainId,
  inputCurrency,
  outputCurrency,
  typedValue,
  independentField
}) {
  const params = new URLSearchParams();
  if (chainId) {
    params.set("chain", getChainInfo(chainId).interfaceName);
  }
  if (outputChainId && outputChainId !== chainId) {
    params.set("outputChain", getChainInfo(outputChainId).interfaceName);
  }
  if (inputCurrency) {
    params.set("inputCurrency", inputCurrency);
  }
  if (outputCurrency) {
    params.set("outputCurrency", outputCurrency);
  }
  if (typedValue) {
    params.set("value", typedValue);
  }
  if (independentField) {
    params.set("field", independentField);
  }
  return params;
}
export function serializeSwapStateToURLParameters(state) {
  const { inputCurrency, outputCurrency, typedValue, independentField, chainId } = state;
  const hasValidInput = (inputCurrency || outputCurrency) && typedValue;
  return "?" + createBaseSwapURLParams({
    chainId,
    outputChainId: outputCurrency?.chainId !== inputCurrency?.chainId ? outputCurrency?.chainId : void 0,
    inputCurrency: inputCurrency ? inputCurrency.isNative ? NATIVE_CHAIN_ID : inputCurrency.address : void 0,
    outputCurrency: outputCurrency ? outputCurrency.isNative ? NATIVE_CHAIN_ID : outputCurrency.address : void 0,
    typedValue: hasValidInput ? typedValue : void 0,
    independentField: hasValidInput ? independentField : void 0
  }).toString();
}
export function serializeSwapAddressesToURLParameters({
  inputTokenAddress,
  outputTokenAddress,
  chainId,
  outputChainId
}) {
  const chainIdOrDefault = chainId ?? UniverseChainId.Mainnet;
  return "?" + createBaseSwapURLParams({
    chainId: chainId ?? void 0,
    outputChainId: outputChainId ?? void 0,
    inputCurrency: inputTokenAddress ? inputTokenAddress === getNativeAddress(chainIdOrDefault) ? NATIVE_CHAIN_ID : inputTokenAddress : void 0,
    outputCurrency: outputTokenAddress ? outputTokenAddress === getNativeAddress(outputChainId ?? chainIdOrDefault) ? NATIVE_CHAIN_ID : outputTokenAddress : void 0
  }).toString();
}
export function queryParametersToCurrencyState(parsedQs) {
  const chainId = getParsedChainId(parsedQs);
  const outputChainId = getParsedChainId(parsedQs, CurrencyField.OUTPUT);
  const inputCurrencyId = parseCurrencyFromURLParameter(parsedQs.inputCurrency ?? parsedQs.inputcurrency);
  const parsedOutputCurrencyId = parseCurrencyFromURLParameter(parsedQs.outputCurrency ?? parsedQs.outputcurrency);
  const outputCurrencyId = parsedOutputCurrencyId === inputCurrencyId && outputChainId === chainId ? void 0 : parsedOutputCurrencyId;
  const hasCurrencyInput = inputCurrencyId || outputCurrencyId;
  const value = hasCurrencyInput ? parseFromURLParameter(parsedQs.value) : void 0;
  const field = value ? parseFromURLParameter(parsedQs.field) : void 0;
  return {
    inputCurrencyId,
    outputCurrencyId,
    value,
    field,
    chainId,
    outputChainId
  };
}
export function useInitialCurrencyState() {
  const { setIsUserSelectedToken } = useMultichainContext();
  const { defaultChainId, isTestnetModeEnabled } = useEnabledChains();
  const { useParsedQueryString } = useUrlContext();
  const parsedQs = useParsedQueryString();
  const parsedCurrencyState = useMemo(() => {
    return queryParametersToCurrencyState(parsedQs);
  }, [parsedQs]);
  const supportedChainId = useSupportedChainId(parsedCurrencyState.chainId ?? defaultChainId) ?? UniverseChainId.Mainnet;
  const supportedChainInfo = getChainInfo(supportedChainId);
  const isSupportedChainCompatible = isTestnetModeEnabled === !!supportedChainInfo.testnet;
  const hasCurrencyQueryParams = parsedCurrencyState.inputCurrencyId || parsedCurrencyState.outputCurrencyId || parsedCurrencyState.chainId;
  useEffect(() => {
    if (parsedCurrencyState.inputCurrencyId || parsedCurrencyState.outputCurrencyId) {
      setIsUserSelectedToken(true);
    }
  }, [parsedCurrencyState.inputCurrencyId, parsedCurrencyState.outputCurrencyId, setIsUserSelectedToken]);
  const { initialInputCurrencyAddress, initialChainId } = useMemo(() => {
    if (!hasCurrencyQueryParams || !isSupportedChainCompatible) {
      return {
        initialInputCurrencyAddress: "ETH",
        initialChainId: defaultChainId
      };
    }
    if (parsedCurrencyState.inputCurrencyId) {
      return {
        initialInputCurrencyAddress: parsedCurrencyState.inputCurrencyId,
        initialChainId: supportedChainId
      };
    }
    return {
      initialInputCurrencyAddress: parsedCurrencyState.outputCurrencyId ? void 0 : "ETH",
      initialChainId: supportedChainId
    };
  }, [
    hasCurrencyQueryParams,
    parsedCurrencyState.outputCurrencyId,
    parsedCurrencyState.inputCurrencyId,
    isSupportedChainCompatible,
    supportedChainId,
    defaultChainId
  ]);
  const outputChainIsSupported = useSupportedChainId(parsedCurrencyState.outputChainId);
  const initialOutputCurrencyAddress = useMemo(
    () => (
      // clear output if identical unless there's a supported outputChainId which means we're bridging
      initialInputCurrencyAddress === parsedCurrencyState.outputCurrencyId && !outputChainIsSupported ? void 0 : parsedCurrencyState.outputCurrencyId
    ),
    [initialInputCurrencyAddress, parsedCurrencyState.outputCurrencyId, outputChainIsSupported]
  );
  const initialInputCurrency = useCurrency(initialInputCurrencyAddress, initialChainId);
  const initialOutputCurrency = useCurrency(
    initialOutputCurrencyAddress,
    parsedCurrencyState.outputChainId ?? initialChainId
  );
  const initialTypedValue = initialInputCurrency || initialOutputCurrency ? parsedCurrencyState.value : void 0;
  const initialFieldUpper = parsedCurrencyState.field && typeof parsedCurrencyState.field === "string" ? parsedCurrencyState.field.toUpperCase() : void 0;
  const initialField = initialTypedValue && initialFieldUpper && initialFieldUpper in CurrencyField ? CurrencyField[initialFieldUpper] : void 0;
  return {
    initialInputCurrency,
    initialOutputCurrency,
    initialTypedValue,
    initialField,
    initialChainId,
    triggerConnect: !!parsedQs.connect
  };
}

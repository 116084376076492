"use strict";
import styled from "lib/styled-components";
import { darken } from "polished";
import { forwardRef } from "react";
import { Button as RebassButton } from "rebass/styled-components";
const ButtonOverlay = styled.div`
  background-color: transparent;
  bottom: 0;
  border-radius: inherit;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 150ms ease background-color;
  width: 100%;
`;
const BaseButton = styled(RebassButton)`
  padding: ${({ padding }) => padding ?? "16px"};
  width: ${({ width }) => width ?? "100%"};
  line-height: 24px;
  font-weight: 535;
  text-align: center;
  border-radius: ${({ $borderRadius }) => $borderRadius ?? "16px"};
  outline: none;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.neutral1};
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    opacity: 50%;
    cursor: auto;
    pointer-events: none;
  }

  will-change: transform;
  transition: transform 450ms ease;
  transform: perspective(1px) translateZ(0);

  > * {
    user-select: none;
  }

  > a {
    text-decoration: none;
  }
`;
export const ButtonGray = styled(BaseButton)`
  background-color: ${({ theme }) => theme.surface1};
  color: ${({ theme }) => theme.neutral2};
  border: 1px solid ${({ theme }) => theme.surface3};
  font-size: 16px;
  font-weight: 535;

  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.surface2)};
  }
  &:active {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.surface2)};
  }
`;
export var ButtonEmphasis = /* @__PURE__ */ ((ButtonEmphasis2) => {
  ButtonEmphasis2["highSoft"] = "highSoft";
  ButtonEmphasis2["medium"] = "medium";
  return ButtonEmphasis2;
})(ButtonEmphasis || {});
function pickThemeButtonBackgroundColor({ theme, emphasis }) {
  switch (emphasis) {
    case "highSoft" /* highSoft */:
      return theme.accent2;
    case "medium" /* medium */:
    default:
      return theme.surface3;
  }
}
function pickThemeButtonTextColor({ theme, emphasis }) {
  switch (emphasis) {
    case "highSoft" /* highSoft */:
      return theme.accent1;
    case "medium" /* medium */:
    default:
      return theme.neutral1;
  }
}
const BaseThemeButton = styled.button`
  align-items: center;
  background-color: ${pickThemeButtonBackgroundColor};
  border-radius: 16px;
  border: 0;
  color: ${pickThemeButtonTextColor};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 535;
  gap: 12px;
  justify-content: center;
  line-height: 20px;
  padding: 10px 12px;
  position: relative;
  transition: 150ms ease opacity;
  user-select: none;

  :active {
    ${ButtonOverlay} {
      background-color: ${({ theme }) => theme.deprecated_stateOverlayPressed};
    }
  }
  :focus {
    ${ButtonOverlay} {
      background-color: ${({ theme }) => theme.deprecated_stateOverlayPressed};
    }
  }
  :hover {
    ${ButtonOverlay} {
      background-color: ${({ theme }) => theme.deprecated_stateOverlayHover};
    }
  }
  :disabled {
    cursor: default;
    opacity: 0.6;
  }
  :disabled:active,
  :disabled:focus,
  :disabled:hover {
    ${ButtonOverlay} {
      background-color: transparent;
    }
  }
`;
export const ThemeButton = forwardRef(function ThemeButton2({ children, ...rest }, ref) {
  return <BaseThemeButton {...rest} ref={ref}><ButtonOverlay />{children}</BaseThemeButton>;
});

"use strict";
import { ChartType } from "components/Charts/utils";
import { DataQuality } from "components/Tokens/TokenDetails/ChartSection/util";
import { getCurrencyAddressWithWrap } from "pages/Pool/Positions/create/utils";
import { useMemo } from "react";
import {
  usePoolPriceHistoryQuery
} from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { isSameAddress } from "utilities/src/addresses";
export function usePoolPriceChartData(variables, currencyA, protocolVersion, sortedCurrencyAAddress) {
  const { data, loading } = usePoolPriceHistoryQuery({ variables, skip: !variables?.addressOrId });
  return useMemo(() => {
    const { priceHistory } = data?.v2Pair ?? data?.v3Pool ?? data?.v4Pool ?? {};
    const entries = priceHistory?.filter((price) => price !== null).map((price) => {
      const value = isSameAddress(sortedCurrencyAAddress, getCurrencyAddressWithWrap(currencyA, protocolVersion)) ? price?.token0Price : price?.token1Price;
      return {
        time: price.timestamp,
        value,
        open: value,
        high: value,
        low: value,
        close: value
      };
    }) ?? [];
    const dataQuality = loading || !priceHistory || !priceHistory.length ? DataQuality.INVALID : DataQuality.VALID;
    return { chartType: ChartType.PRICE, entries, loading, dataQuality };
  }, [data?.v2Pair, data?.v3Pool, data?.v4Pool, loading, sortedCurrencyAAddress, currencyA, protocolVersion]);
}

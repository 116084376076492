import { Fragment, cloneElement, forwardRef, useMemo } from "react";
import { withStaticProperties } from "tamagui";
import { ThemedIcon } from "ui/src/components/buttons/Button/components/ThemedIcon";
import { useIsStringOrTransTag } from "ui/src/components/buttons/Button/hooks/useIsStringOrTransTag";
import { getIconPosition } from "ui/src/components/buttons/Button/utils/getIconPosition";
import { getIsButtonDisabled } from "ui/src/components/buttons/Button/utils/getIsButtonDisabled";
import { DropdownButtonFrame } from "ui/src/components/buttons/DropdownButton/DropdownButtonFrame";
import { DropdownButtonText } from "ui/src/components/buttons/DropdownButton/DropdownButtonText";
import { EXPANDED_COLOR, EXPANDED_HOVER_COLOR } from "ui/src/components/buttons/DropdownButton/constants";
import { RotatableChevron } from "ui/src/components/icons";
import { Flex } from "ui/src/components/layout/Flex";
const LeftContainer = ({ elementPositioning, children, icon, label }) => {
  if (elementPositioning === "grouped" && icon && label) {
    return <Flex row alignItems="center" gap="$gap12">{children}</Flex>;
  }
  return <Fragment>{children}</Fragment>;
};
const DropdownButtonComponent = forwardRef(function DropdownButton2({ children, emphasis = "secondary", icon, isDisabled, elementPositioning = "equal", isExpanded, ...props }, ref) {
  const disabled = getIsButtonDisabled({ isDisabled, loading: void 0 });
  const isStringOrTransTag = useIsStringOrTransTag(children);
  const SpacingElement = useMemo(() => {
    return elementPositioning !== "grouped" && icon && children ? <Flex flexGrow={1} /> : null;
  }, [elementPositioning, icon, children]);
  const iconGroupItemHover = useMemo(
    () => isExpanded ? {
      color: EXPANDED_HOVER_COLOR
    } : void 0,
    [isExpanded]
  );
  const iconColor = isExpanded ? EXPANDED_COLOR : void 0;
  return <DropdownButtonFrame
    ref={ref}
    iconPosition={getIconPosition("before")}
    emphasis={emphasis}
    isDisabled={disabled}
    isExpanded={isExpanded}
    {...props}
  ><LeftContainer icon={icon} elementPositioning={elementPositioning} label={children}><ThemedIcon
    isDisabled={isDisabled}
    emphasis={emphasis}
    size={props.size}
    variant="default"
    typeOfButton="button"
  >{icon ? cloneElement(icon, {
    color: iconColor,
    "$group-item-hover": iconGroupItemHover
  }) : void 0}</ThemedIcon>{SpacingElement}{isStringOrTransTag ? <DropdownButtonText>{children}</DropdownButtonText> : children}</LeftContainer>{SpacingElement}<ThemedIcon isDisabled={isDisabled} emphasis={emphasis} size={props.size} variant="default" typeOfButton="button"><RotatableChevron
    color={iconColor}
    animation="fast"
    direction={isExpanded ? "up" : "down"}
    $group-item-hover={iconGroupItemHover}
  /></ThemedIcon></DropdownButtonFrame>;
});
export const DropdownButton = withStaticProperties(DropdownButtonComponent, {
  Text: DropdownButtonText,
  Icon: ThemedIcon,
  Chevron: RotatableChevron
});

"use strict";
import { colorsDark, colorsLight } from "ui/src/theme";
export const colors = {
  white: "#FFFFFF",
  black: "#000000",
  gray50: "#F5F6FC",
  gray100: "#E8ECFB",
  gray150: "#D2D9EE",
  gray200: "#B8C0DC",
  gray250: "#A6AFCA",
  gray300: "#98A1C0",
  gray350: "#888FAB",
  gray400: "#7780A0",
  gray450: "#6B7594",
  gray500: "#5D6785",
  gray550: "#505A78",
  gray600: "#404A67",
  gray650: "#333D59",
  gray700: "#293249",
  gray750: "#1B2236",
  gray800: "#131A2A",
  gray850: "#0E1524",
  gray900: "#0D111C",
  gray950: "#080B11",
  pink50: "#F9ECF1",
  pink100: "#FFD9E4",
  pink200: "#FBA4C0",
  pink300: "#FF6FA3",
  pink400: "#FB118E",
  pink500: "#C41969",
  pink600: "#8C0F49",
  pink700: "#55072A",
  pink800: "#350318",
  pink900: "#2B000B",
  pinkBase: "#FC74FE",
  pinkVibrant: "#F50DB4",
  red50: "#FAECEA",
  red100: "#FED5CF",
  red200: "#FEA79B",
  red300: "#FD766B",
  red400: "#FA2B39",
  red500: "#C4292F",
  red600: "#891E20",
  red700: "#530F0F",
  red800: "#380A03",
  red900: "#240800",
  redVibrant: "#F14544",
  yellow50: "#F6F2D5",
  yellow100: "#DBBC19",
  yellow200: "#DBBC19",
  yellow300: "#BB9F13",
  yellow400: "#A08116",
  yellow500: "#866311",
  yellow600: "#5D4204",
  yellow700: "#3E2B04",
  yellow800: "#231902",
  yellow900: "#180F02",
  yellowVibrant: "#FAF40A",
  // TODO: add gold 50-900
  gold200: "#EEB317",
  gold400: "#B17900",
  goldVibrant: "#FEB239",
  green50: "#E3F3E6",
  green100: "#BFEECA",
  green200: "#76D191",
  green300: "#40B66B",
  green400: "#209853",
  green500: "#0B783E",
  green600: "#0C522A",
  green700: "#053117",
  green800: "#091F10",
  green900: "#09130B",
  greenVibrant: "#5CFE9D",
  blue50: "#EDEFF8",
  blue100: "#DEE1FF",
  blue200: "#ADBCFF",
  blue300: "#869EFF",
  blue400: "#4C82FB",
  blue500: "#1267D6",
  blue600: "#1D4294",
  blue700: "#09265E",
  blue800: "#0B193F",
  blue900: "#040E34",
  blueVibrant: "#587BFF",
  // TODO: add magenta 50-900
  magenta300: "#FD82FF",
  magentaVibrant: "#FC72FF",
  purple300: "#8440F2",
  purple900: "#1C0337",
  purpleVibrant: "#6100FF",
  // TODO: add all other vibrant variations
  networkEthereum: "#627EEA",
  networkOptimism: "#FF0420",
  networkOptimismSoft: "rgba(255, 4, 32, 0.16)",
  networkPolygon: "#A457FF",
  networkArbitrum: "#28A0F0",
  networkBsc: "#F0B90B",
  networkPolygonSoft: "rgba(164, 87, 255, 0.16)",
  networkEthereumSoft: "rgba(98, 126, 234, 0.16)",
  networkBase: "#0052FF",
  networkBlast: "#fcfc03",
  // Spore colors
  neutral1_dark: colorsDark.neutral1,
  neutral1Hovered_dark: colorsDark.neutral1Hovered,
  neutral2_dark: colorsDark.neutral2,
  neutral2Hovered_dark: colorsDark.neutral2Hovered,
  neutral3_dark: colorsDark.neutral3,
  neutral3Hovered_dark: colorsDark.neutral3Hovered,
  surface1_dark: colorsDark.surface1,
  surface1_hovered_dark: colorsDark.surface1Hovered,
  surface2_dark: colorsDark.surface2,
  surface2_hovered_dark: colorsDark.surface2Hovered,
  surface3_dark: colorsDark.surface3,
  surface3Solid_dark: "#2F2F2F",
  surface3_hovered_dark: colorsDark.surface3Hovered,
  surface4_dark: colorsDark.surface4,
  surface5_dark: colorsDark.surface5,
  accent1_dark: colorsDark.accent1,
  accent1Hovered_dark: colorsDark.accent1Hovered,
  accent2_dark: colorsDark.accent2,
  accent2Hovered_dark: colorsDark.accent2Hovered,
  accent2Solid_dark: colorsDark.accent2Solid,
  accent3_dark: colorsDark.accent3,
  neutral1_light: colorsLight.neutral1,
  neutral1Hovered_light: colorsLight.neutral1Hovered,
  neutral2_light: colorsLight.neutral2,
  neutral2Hovered_light: colorsLight.neutral2Hovered,
  neutral3_light: colorsLight.neutral3,
  neutral3Hovered_light: colorsLight.neutral3Hovered,
  surface1_light: colorsLight.surface1,
  surface1_hovered_light: colorsLight.surface1Hovered,
  surface2_light: colorsLight.surface2,
  surface2_hovered_light: colorsLight.surface2Hovered,
  surface3_light: colorsLight.surface3,
  surface3Solid_light: "#ECECEC",
  surface3_hovered_light: colorsLight.surface3Hovered,
  surface4_light: colorsLight.surface4,
  surface5_light: colorsLight.surface5,
  accent1_light: colorsLight.accent1,
  accent1Hovered_light: colorsLight.accent1Hovered,
  accent2_light: colorsLight.accent2,
  accent2Hovered_light: colorsLight.accent2Hovered,
  accent2Solid_light: colorsLight.accent2Solid,
  accent3_light: colorsLight.accent3,
  success_light: colorsLight.statusSuccess,
  critical_light: colorsLight.statusCritical,
  success_dark: colorsDark.statusSuccess,
  critical_dark: colorsDark.statusCritical,
  critical2_dark: colorsDark.statusCritical2,
  critical2_light: colorsLight.statusCritical2,
  scrim: colorsLight.scrim
};
const commonTheme = {
  white: colors.white,
  black: colors.black,
  chain_1: colors.networkEthereum,
  chain_3: colors.yellow400,
  chain_4: colors.pink400,
  chain_5: colors.green400,
  chain_10: colors.networkOptimism,
  chain_137: colors.networkPolygon,
  chain_42: colors.networkArbitrum,
  chain_56: colors.networkBsc,
  chain_420: colors.networkOptimism,
  chain_42161: colors.networkArbitrum,
  chain_421613: colors.networkArbitrum,
  chain_421614: colors.networkArbitrum,
  chain_80001: colors.networkPolygon,
  chain_43114: colors.networkOptimism,
  chain_137_background: colors.purple900,
  chain_10_background: colors.red900,
  chain_43114_background: colors.red900,
  chain_42161_background: colors.blue900,
  chain_84531: colors.networkBase,
  chain_56_background: colors.networkBsc,
  chain_81457: colors.networkBlast,
  chain_7777777: colors.neutral1_light,
  promotional: colors.magenta300,
  brandedGradient: "linear-gradient(139.57deg, #FF79C9 4.35%, #FFB8E2 96.44%);",
  promotionalGradient: colors.accent1_light
};
export const darkTheme = {
  ...commonTheme,
  background: colors.black,
  neutral1: colors.neutral1_dark,
  neutral1Hovered: colors.neutral1Hovered_dark,
  neutral2: colors.neutral2_dark,
  neutral2Hovered: colors.neutral2Hovered_dark,
  neutral3: colors.neutral3_dark,
  neutral3Hovered: colors.neutral3Hovered_dark,
  neutralContrast: colors.white,
  surface1: colors.surface1_dark,
  surface1Hovered: colors.surface1_hovered_dark,
  surface2: colors.surface2_dark,
  surface2Hovered: colors.surface2_hovered_dark,
  surface3: colors.surface3_dark,
  surface3Solid: colors.surface3Solid_dark,
  surface3Hovered: colors.surface3_hovered_dark,
  surface4: colors.surface4_dark,
  surface5: colors.surface5_dark,
  accent1: colors.accent1_dark,
  accent1Hovered: colors.accent1Hovered_dark,
  accent2: colors.accent2_dark,
  accent2Hovered: colors.accent2Hovered_dark,
  accent2Solid: colors.accent2Solid_dark,
  accent3: colors.accent3_dark,
  token0: colors.accent1_dark,
  token1: colors.accent3_dark,
  success: colors.success_dark,
  critical: colors.critical_dark,
  critical2: colors.critical2_dark,
  scrim: colors.scrim,
  warning2: colors.gold200,
  blue400: colors.blue400
};
export const lightTheme = {
  ...commonTheme,
  background: colors.white,
  neutral1: colors.neutral1_light,
  neutral1Hovered: colors.neutral1Hovered_light,
  neutral2: colors.neutral2_light,
  neutral2Hovered: colors.neutral2Hovered_light,
  neutral3: colors.neutral3_light,
  neutral3Hovered: colors.neutral3Hovered_light,
  neutralContrast: colors.white,
  surface1: colors.surface1_light,
  surface1Hovered: colors.surface1_hovered_light,
  surface2: colors.surface2_light,
  surface2Hovered: colors.surface2_hovered_light,
  surface3: colors.surface3_light,
  surface3Solid: colors.surface3Solid_light,
  surface3Hovered: colors.surface3_hovered_light,
  surface4: colors.surface4_light,
  surface5: colors.surface5_light,
  accent1: colors.accent1_light,
  accent1Hovered: colors.accent1Hovered_light,
  accent2: colors.accent2_light,
  accent2Hovered: colors.accent2Hovered_light,
  accent2Solid: colors.accent2Solid_light,
  accent3: colors.accent3_light,
  token0: colors.accent1_light,
  token1: colors.accent3_light,
  success: colors.success_light,
  critical: colors.critical_light,
  critical2: colors.critical2_light,
  scrim: colors.scrim,
  warning2: colors.gold200,
  blue400: colors.blue400
};

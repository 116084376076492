import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CONNECTION_PROVIDER_IDS } from "uniswap/src/constants/web3";
import { useConnector } from "uniswap/src/contexts/UniswapContext";
import { useFeatureFlaggedChainIds } from "uniswap/src/features/chains/hooks/useFeatureFlaggedChainIds";
import { useOrderedChainIds } from "uniswap/src/features/chains/hooks/useOrderedChainIds";
import { ALL_CHAIN_IDS } from "uniswap/src/features/chains/types";
import { getEnabledChains } from "uniswap/src/features/chains/utils";
import { selectIsTestnetModeEnabled } from "uniswap/src/features/settings/selectors";
import { isTestEnv } from "utilities/src/environment/env";
import { logger } from "utilities/src/logger/logger";
import { isInterface } from "utilities/src/platform";
function useConnectorWithCatch() {
  try {
    return useConnector();
  } catch (e) {
    if (isInterface && !isTestEnv()) {
      logger.error(e, {
        tags: { file: "src/features/settings/hooks", function: "useConnectorWithCatch" }
      });
    }
    return void 0;
  }
}
function useConnectorSupportedChains(connector) {
  return useMemo(() => {
    switch (connector?.type) {
      case CONNECTION_PROVIDER_IDS.UNISWAP_WALLET_CONNECT_CONNECTOR_ID:
      case CONNECTION_PROVIDER_IDS.WALLET_CONNECT_CONNECTOR_ID:
        return connector.getNamespaceChainsIds?.().length ? connector.getNamespaceChainsIds?.() : ALL_CHAIN_IDS;
      default:
        return ALL_CHAIN_IDS;
    }
  }, [connector]);
}
function useConnectedWalletSupportedChains() {
  const connector = useConnectorWithCatch();
  return useConnectorSupportedChains(connector);
}
export function useEnabledChains() {
  const featureFlaggedChainIds = useFeatureFlaggedChainIds();
  const connectedWalletChainIds = useConnectedWalletSupportedChains();
  const isTestnetModeEnabled = useSelector(selectIsTestnetModeEnabled);
  const {
    chains: unorderedChains,
    gqlChains,
    defaultChainId
  } = useMemo(
    () => getEnabledChains({
      isTestnetModeEnabled,
      connectedWalletChainIds,
      featureFlaggedChainIds
    }),
    [isTestnetModeEnabled, connectedWalletChainIds, featureFlaggedChainIds]
  );
  const orderedChains = useOrderedChainIds(unorderedChains);
  return useMemo(() => {
    return { chains: orderedChains, gqlChains, defaultChainId, isTestnetModeEnabled };
  }, [defaultChainId, gqlChains, isTestnetModeEnabled, orderedChains]);
}
export function useEnabledChainsWithConnector(connector) {
  const featureFlaggedChainIds = useFeatureFlaggedChainIds();
  const connectedWalletChainIds = useConnectorSupportedChains(connector);
  const isTestnetModeEnabled = useSelector(selectIsTestnetModeEnabled);
  return useMemo(
    () => getEnabledChains({ isTestnetModeEnabled, connectedWalletChainIds, featureFlaggedChainIds }),
    [isTestnetModeEnabled, connectedWalletChainIds, featureFlaggedChainIds]
  );
}

"use strict";
import { useAccountDrawer } from "components/AccountDrawer/MiniPortfolio/hooks";
import { useAccount } from "hooks/useAccount";
import { useEthersProvider } from "hooks/useEthersProvider";
import { useEthersSigner } from "hooks/useEthersSigner";
import { useShowSwapNetworkNotification } from "hooks/useShowSwapNetworkNotification";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { serializeSwapAddressesToURLParameters } from "state/swap/hooks";
import { UniswapProvider } from "uniswap/src/contexts/UniswapContext";
import { AccountType } from "uniswap/src/features/accounts/types";
import { currencyIdToAddress, currencyIdToChain } from "uniswap/src/utils/currencyId";
import { getTokenDetailsURL } from "uniswap/src/utils/linking";
function useWebProvider(chainId) {
  return useEthersProvider({ chainId });
}
function useWagmiAccount() {
  const account = useAccount();
  return useMemo(() => {
    if (!account.address) {
      return {
        account: void 0,
        connector: account.connector
      };
    }
    return {
      account: {
        address: account.address,
        type: AccountType.SignerMnemonic
      },
      connector: account.connector
    };
  }, [account.address, account.connector]);
}
export function WebUniswapProvider({ children }) {
  const { account, connector } = useWagmiAccount();
  const signer = useEthersSigner();
  const showSwapNetworkNotification = useShowSwapNetworkNotification();
  const accountDrawer = useAccountDrawer();
  const navigate = useNavigate();
  const navigateToFiatOnRamp = useCallback(() => navigate(`/buy`, { replace: true }), [navigate]);
  const navigateToSwapFlow = useCallback(
    ({ inputCurrencyId, outputCurrencyId }) => {
      const queryParams = serializeSwapAddressesToURLParameters({
        inputTokenAddress: inputCurrencyId ? currencyIdToAddress(inputCurrencyId) : void 0,
        outputTokenAddress: outputCurrencyId ? currencyIdToAddress(outputCurrencyId) : void 0,
        chainId: inputCurrencyId ? currencyIdToChain(inputCurrencyId) : void 0,
        outputChainId: outputCurrencyId ? currencyIdToChain(outputCurrencyId) : void 0
      });
      navigate(`/swap${queryParams}`, { replace: true });
    },
    [navigate]
  );
  const navigateToTokenDetails = useCallback(
    (currencyId) => {
      const url = getTokenDetailsURL({
        address: currencyIdToAddress(currencyId),
        chain: currencyIdToChain(currencyId) ?? void 0
      });
      navigate(url);
    },
    [navigate]
  );
  return <UniswapProvider
    account={account}
    signer={signer}
    connector={connector}
    useProviderHook={useWebProvider}
    onSwapChainsChanged={showSwapNetworkNotification}
    navigateToFiatOnRamp={navigateToFiatOnRamp}
    navigateToSwapFlow={navigateToSwapFlow}
    navigateToTokenDetails={navigateToTokenDetails}
    onConnectWallet={accountDrawer.open}
  >{children}</UniswapProvider>;
}

import { Children, isValidElement, useMemo } from "react";
import { Trans } from "react-i18next";
import { Flex } from "ui/src/components/layout";
const hasDirectOrResolvedTransChild = (child) => {
  if (!isValidElement(child)) {
    return false;
  }
  if (child.type === Trans) {
    return true;
  }
  if (child.type === Flex) {
    return false;
  }
  return true;
};
export const useIsStringOrTransTag = (children) => {
  const arrayedChildren = useMemo(() => Children.toArray(children), [children]);
  const numberOfArrayedChildren = Children.count(children);
  const firstChild = arrayedChildren[0];
  const isChildrenAString = typeof children === "string";
  const isStringOrTransTag = useMemo(() => {
    if (isChildrenAString) {
      return true;
    }
    return numberOfArrayedChildren === 1 && hasDirectOrResolvedTransChild(firstChild);
  }, [isChildrenAString, numberOfArrayedChildren, firstChild]);
  return isStringOrTransTag;
};

"use strict";
import "zone.js";
Zone[Zone.__symbol__("ignoreConsoleErrorUncaughtError")] = true;
async function datadogAdaptor(context, callback) {
  const start = performance.now();
  const callbackContext = {
    child(context2, callback2) {
      return datadogAdaptor(context2, callback2);
    },
    now() {
      return performance.now() - start;
    }
  };
  try {
    return await callback(callbackContext);
  } finally {
    if (!context.op.startsWith("http")) {
      performance.measure(context.op, { start });
    }
  }
}
export async function trace(context, callback) {
  const parentTrace = Zone.current.get("trace");
  if (parentTrace) {
    return parentTrace?.(context, callback);
  } else {
    return datadogAdaptor(context, callback);
  }
}

import { isBetaEnv, isDevEnv } from "utilities/src/environment/env";
export var DatadogEnvironment = /* @__PURE__ */ ((DatadogEnvironment2) => {
  DatadogEnvironment2["DEV"] = "dev";
  DatadogEnvironment2["BETA"] = "beta";
  DatadogEnvironment2["PROD"] = "prod";
  return DatadogEnvironment2;
})(DatadogEnvironment || {});
export function getDatadogEnvironment() {
  if (isDevEnv()) {
    return "dev" /* DEV */;
  }
  if (isBetaEnv()) {
    return "beta" /* BETA */;
  }
  return "prod" /* PROD */;
}

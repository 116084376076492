import { createStyledContext } from "tamagui";
export const EXPANDED_COLOR = "$neutral2";
export const EXPANDED_HOVER_COLOR = "$neutral2Hovered";
export const dropdownButtonStyledContext = createStyledContext({
  size: "medium",
  variant: "default",
  emphasis: "primary",
  isDisabled: false,
  isExpanded: false
});

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * The protocol of the liquidity pool.
 */
export enum LPprotocolItems {
    V2 = 'V2',
    V3 = 'V3',
    V4 = 'V4',
}

"use strict";
import { Price } from "@uniswap/sdk-core";
import { formatTimestamp } from "components/AccountDrawer/MiniPortfolio/formatTimestamp";
import { DetailLineItem } from "components/swap/DetailLineItem";
import TradePrice from "components/swap/TradePrice";
import { Trans } from "react-i18next";
import { ExternalLink } from "theme/components/Links";
import { ellipseMiddle } from "utilities/src/addresses";
import { NumberType, useFormatter } from "utils/formatNumbers";
export var OffchainOrderLineItemType = /* @__PURE__ */ ((OffchainOrderLineItemType2) => {
  OffchainOrderLineItemType2["EXCHANGE_RATE"] = "EXCHANGE_RATE";
  OffchainOrderLineItemType2["EXPIRY"] = "EXPIRY";
  OffchainOrderLineItemType2["NETWORK_COST"] = "NETWORK_COST";
  OffchainOrderLineItemType2["TRANSACTION_ID"] = "TRANSACTION_ID";
  return OffchainOrderLineItemType2;
})(OffchainOrderLineItemType || {});
function useLineItem(details) {
  const { formatNumber } = useFormatter();
  switch (details.type) {
    case "EXCHANGE_RATE" /* EXCHANGE_RATE */:
      return {
        Label: () => <Trans i18nKey="common.rate">Rate</Trans>,
        Value: () => <TradePrice
          price={new Price(
            details.amounts?.inputAmount.currency,
            details.amounts?.outputAmount.currency,
            details.amounts?.inputAmount.quotient,
            details.amounts?.outputAmount.quotient
          )}
        />
      };
    case "EXPIRY" /* EXPIRY */:
      return {
        Label: () => <Trans i18nKey="common.expiry" />,
        Value: () => <span>{details.order.expiry && formatTimestamp(details.order.expiry * 1e3)}</span>
      };
    case "NETWORK_COST" /* NETWORK_COST */:
      return {
        Label: () => <Trans i18nKey="common.networkCost" />,
        Value: () => <span>{formatNumber({ input: 0, type: NumberType.FiatGasPrice })}</span>
      };
    case "TRANSACTION_ID" /* TRANSACTION_ID */:
      return {
        Label: () => <Trans i18nKey="common.transactionId" />,
        Value: () => <ExternalLink href={details.explorerLink}>{ellipseMiddle(details.order.txHash ?? "")}</ExternalLink>
      };
    default:
      return void 0;
  }
}
export function OffchainOrderLineItem(props) {
  const LineItem = useLineItem(props);
  if (!LineItem) {
    return null;
  }
  return <DetailLineItem LineItem={LineItem} />;
}

"use strict";
import { ApprovedCheckmarkIcon } from "nft/components/iconExports";
import { Avatar, Flex, styled } from "ui/src";
import { Text } from "ui/src/components/text/Text";
import { iconSizes } from "ui/src/theme";
import { getCountryFlagSvgUrl } from "uniswap/src/features/fiatOnRamp/utils";
const RowWrapper = styled(Flex, {
  row: true,
  height: "$spacing60",
  px: "$spacing20",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  hoverStyle: {
    backgroundColor: "$surface2"
  }
});
export function CountryListRow({ style, country, selectedCountry, onClick }) {
  if (!country) {
    return null;
  }
  const countryFlagUrl = getCountryFlagSvgUrl(country.countryCode);
  return <RowWrapper style={style} onPress={onClick}><Flex row alignItems="center" gap="$spacing12"><Avatar circular size={iconSizes.icon32}><Avatar.Image accessibilityLabel="Country flag" src={countryFlagUrl} alt={country.countryCode} /><Avatar.Fallback backgroundColor="$neutral3" /></Avatar><Text variant="body2">{country.displayName}</Text></Flex>{selectedCountry?.countryCode === country.countryCode && <ApprovedCheckmarkIcon height={24} width={24} />}</RowWrapper>;
}

import { datadogEnabledBuild, localDevDatadogEnabled } from "utilities/src/environment/constants";
import { logErrorToDatadog, logToDatadog, logWarningToDatadog } from "utilities/src/logger/datadog/Datadog";
import { isInterface, isMobileApp, isWeb } from "utilities/src/platform";
const MAX_CHAR_LIMIT = 8192;
let datadogEnabled = false;
export const logger = {
  debug: (fileName, functionName, message, ...args) => logMessage("debug", fileName, functionName, message, ...args),
  info: (fileName, functionName, message, ...args) => logMessage("info", fileName, functionName, message, ...args),
  warn: (fileName, functionName, message, ...args) => logMessage("warn", fileName, functionName, message, ...args),
  error: (error, captureContext) => logException(error, captureContext),
  setDatadogEnabled: (enabled) => {
    datadogEnabled = enabled || localDevDatadogEnabled;
  }
};
function logMessage(level, fileName, functionName, message, ...args) {
  if (__DEV__ || isInterface) {
    if (isMobileApp && ["log", "debug", "warn"].includes(level)) {
      console.log(...formatMessage(level, fileName, functionName, message), ...args);
    } else {
      console[level](...formatMessage(level, fileName, functionName, message), ...args);
    }
  }
  if (!datadogEnabledBuild || !datadogEnabled) {
    return;
  }
  if (level === "warn") {
    logWarningToDatadog(message, {
      level,
      args,
      functionName,
      fileName
    });
  } else if (level === "info") {
    logToDatadog(message, {
      level,
      args,
      functionName,
      fileName
    });
  }
}
function logException(error, captureContext) {
  const updatedContext = addErrorExtras(error, captureContext);
  if (__DEV__ || isInterface) {
    console.error(error, captureContext);
  }
  if (!datadogEnabledBuild || !datadogEnabled) {
    return;
  }
  for (const contextProp of Object.keys(updatedContext.tags)) {
    const prop = contextProp;
    const contextValue = updatedContext.tags[prop];
    if (typeof contextValue === "string") {
      updatedContext.tags[prop] = contextValue.slice(0, MAX_CHAR_LIMIT);
    }
  }
  logErrorToDatadog(error instanceof Error ? error : new Error(`${error}`), updatedContext);
}
export function addErrorExtras(error, captureContext) {
  if (error instanceof Error) {
    const updatedContext = { ...captureContext };
    const extras = {};
    const { nativeStackAndroid, userInfo } = error;
    if (Array.isArray(nativeStackAndroid) && nativeStackAndroid.length > 0) {
      extras.nativeStackAndroid = nativeStackAndroid;
    }
    if (userInfo) {
      extras.userInfo = userInfo;
    }
    updatedContext.extra = { ...updatedContext.extra, ...extras };
    return updatedContext;
  }
  return captureContext;
}
function pad(n, amount = 2) {
  return n.toString().padStart(amount, "0");
}
function formatMessage(level, fileName, functionName, message) {
  const t = /* @__PURE__ */ new Date();
  const timeString = `${pad(t.getHours())}:${pad(t.getMinutes())}:${pad(t.getSeconds())}.${pad(t.getMilliseconds(), 3)}`;
  if (isWeb) {
    return [
      level.toUpperCase(),
      {
        context: {
          time: timeString,
          fileName,
          functionName
        }
      },
      message
    ];
  } else {
    return [`${timeString}::${fileName}#${functionName}`, message];
  }
}

import { useCallback, useEffect, useMemo, useState } from "react";
import { useIsDarkMode } from "ui/src/hooks/useIsDarkMode";
import { useSporeColors } from "ui/src/hooks/useSporeColors";
import { colorsLight } from "ui/src/theme";
import { getExtractedColors } from "ui/src/utils/colors/getExtractedColors";
import { isSVGUri } from "utilities/src/format/urls";
import { useAsyncData } from "utilities/src/react/hooks";
import { hex } from "wcag-contrast";
const MIN_TOKEN_COLOR_CONTRAST_THRESHOLD = 1.95;
export const SPECIAL_CASE_TOKEN_COLORS = {
  // old WBTC
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png": "#F09241",
  // new WBTC
  "https://assets.coingecko.com/coins/images/7598/large/wrapped_bitcoin_wbtc.png?1548822744": "#F09241",
  // DAI
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png": "#FAB01B",
  // UNI
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984/logo.png": "#E6358C",
  // BUSD
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x4Fabb145d64652a948d72533023f6E7A623C7C53/logo.png": "#EFBA09",
  // AI-X
  "https://s2.coinmarketcap.com/static/img/coins/64x64/26984.png": "#29A1F1",
  // ETH
  "https://token-icons.s3.amazonaws.com/eth.png": "#4970D5",
  // HARRYPOTTERSHIBAINUBITCOIN
  "https://assets.coingecko.com/coins/images/30323/large/hpos10i_logo_casino_night-dexview.png?1684117567": "#DE3110",
  // PEPE
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x6982508145454Ce325dDbE47a25d4ec3d2311933/logo.png": "#3EAE14",
  // Unibot V2
  "https://s2.coinmarketcap.com/static/img/coins/64x64/25436.png": "#4A0A4F",
  // UNIBOT v1
  "https://assets.coingecko.com/coins/images/30462/small/logonoline_%281%29.png?1687510315": "#4A0A4F",
  // USDC
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png": "#0066D9",
  // HEX
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39/logo.png": "#F93F8C",
  // MONG
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x1ce270557C1f68Cfb577b856766310Bf8B47FD9C/logo.png": "#A96DFF",
  // ARB
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1/logo.png": "#29A1F1",
  // PSYOP
  "https://s2.coinmarketcap.com/static/img/coins/64x64/25422.png": "#E88F00",
  // MATIC
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0/logo.png": "#A96DFF",
  // TURBO
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xA35923162C49cF95e6BF26623385eb431ad920D3/logo.png": "#BD6E29",
  // AIDOGE
  "https://assets.coingecko.com/coins/images/29852/large/photo_2023-04-18_14-25-28.jpg?1681799160": "#29A1F1",
  // SIMPSON
  "https://assets.coingecko.com/coins/images/30243/large/1111.png?1683692033": "#E88F00",
  // MAKER
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2/logo.png": "#50B197",
  // OX
  "https://assets.coingecko.com/coins/images/30604/large/Logo2.png?1685522119": "#2959D9",
  // ANGLE
  "https://assets.coingecko.com/coins/images/19060/large/ANGLE_Token-light.png?1666774221": "#FF5555",
  // APE
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x4d224452801ACEd8B2F0aebE155379bb5D594381/logo.png": "#054AA9",
  // GUSD
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x056Fd409E1d7A124BD7017459dFEa2F387b6d5Cd/logo.png": "#00A4BD",
  // OGN
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x8207c1FfC5B6804F6024322CcF34F29c3541Ae26/logo.png": "#054AA9",
  // RPL
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xD33526068D116cE69F19A9ee46F0bd304F21A51f/logo.png": "#FF7B4F"
};
const blackAndWhiteSpecialCase = /* @__PURE__ */ new Set([
  // QNT
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x4a220E6096B25EADb88358cb44068A3248254675/logo.png",
  // Xen
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x06450dEe7FD2Fb8E39061434BAbCFC05599a6Fb8/logo.png",
  // FWB
  "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x35bD01FC9d6D5D81CA9E055Db88Dc49aa2c699A8/logo.png"
]);
export function useExtractedColors(imageUrl, options = { fallback: "accent1", cache: true }) {
  const sporeColors = useSporeColors();
  const getImageColors = useCallback(
    async () => getExtractedColors(imageUrl, {
      fallback: sporeColors[options.fallback].val,
      cache: options.cache,
      colorStrategy: options.colorStrategy
    }),
    [imageUrl, options.fallback, options.cache, sporeColors, options.colorStrategy]
  );
  const { data: colors, isLoading: colorsLoading } = useAsyncData(getImageColors);
  return { colors, colorsLoading };
}
function getSpecialCaseTokenColor(imageUrl, isDarkMode) {
  if (imageUrl && blackAndWhiteSpecialCase.has(imageUrl)) {
    return isDarkMode ? "#FFFFFF" : "#000000";
  }
  if (!imageUrl || !SPECIAL_CASE_TOKEN_COLORS[imageUrl]) {
    return null;
  }
  return SPECIAL_CASE_TOKEN_COLORS[imageUrl] ?? null;
}
export function useExtractedTokenColor(imageUrl, tokenName, backgroundColor, defaultColor) {
  const sporeColors = useSporeColors();
  const { colors, colorsLoading } = useExtractedColors(imageUrl);
  const [tokenColor, setTokenColor] = useState(defaultColor);
  const [tokenColorLoading, setTokenColorLoading] = useState(true);
  const isDarkMode = useIsDarkMode();
  const { foreground } = useColorSchemeFromSeed(tokenName ?? "");
  useEffect(() => {
    if (!colorsLoading) {
      setTokenColorLoading(false);
      if (colors !== void 0) {
        setTokenColor(pickContrastPassingTokenColor(colors, backgroundColor));
      }
    }
  }, [backgroundColor, colors, colorsLoading]);
  const specialCaseTokenColor = useMemo(() => {
    return getSpecialCaseTokenColor(imageUrl, isDarkMode);
  }, [imageUrl, isDarkMode]);
  if (specialCaseTokenColor) {
    return { tokenColor: specialCaseTokenColor, tokenColorLoading: false };
  }
  if (isSVGUri(imageUrl)) {
    return { tokenColor: sporeColors.neutral1?.val, tokenColorLoading: false };
  }
  if (!imageUrl) {
    return { tokenColor: foreground, tokenColorLoading: false };
  }
  return { tokenColor, tokenColorLoading };
}
var LOGOLESS_COLORS = /* @__PURE__ */ ((LOGOLESS_COLORS2) => {
  LOGOLESS_COLORS2["PINK"] = "PINK";
  LOGOLESS_COLORS2["ORANGE"] = "ORANGE";
  LOGOLESS_COLORS2["YELLOW"] = "YELLOW";
  LOGOLESS_COLORS2["GREEN"] = "GREEN";
  LOGOLESS_COLORS2["TURQUOISE"] = "TURQUOISE";
  LOGOLESS_COLORS2["CYAN"] = "CYAN";
  LOGOLESS_COLORS2["BLUE"] = "BLUE";
  LOGOLESS_COLORS2["PURPLE"] = "PURPLE";
  return LOGOLESS_COLORS2;
})(LOGOLESS_COLORS || {});
const logolessColorSchemes = {
  // TODO (MOB-2417): update the colors in the global colors file to these and pull from there
  ["PINK" /* PINK */]: {
    light: { foreground: "#FC74FE", background: "#FEF4FF" },
    dark: { foreground: "#FC74FE", background: "#361A37" }
  },
  ["ORANGE" /* ORANGE */]: {
    light: { foreground: "#FF7715", background: "#FFF2F1" },
    dark: { foreground: "#FF7715", background: "#2E0805" }
  },
  ["YELLOW" /* YELLOW */]: {
    light: { foreground: "#FFBF17", background: "#FFFCF2" },
    dark: { foreground: "#FFF612", background: "#1F1E02" }
  },
  ["GREEN" /* GREEN */]: {
    light: { foreground: "#2FBA61", background: "#EEFBF1" },
    dark: { foreground: "#2FBA61", background: "#0F2C1A" }
  },
  ["TURQUOISE" /* TURQUOISE */]: {
    light: { foreground: "#00C3A0", background: "#F7FEEB" },
    dark: { foreground: "#5CFE9D", background: "#1A2A21" }
  },
  ["CYAN" /* CYAN */]: {
    light: { foreground: "#2ABDFF", background: "#EBF8FF" },
    dark: { foreground: "#2ABDFF", background: "#15242B" }
  },
  ["BLUE" /* BLUE */]: {
    light: { foreground: "#3271FF", background: "#EFF4FF" },
    dark: { foreground: "#3271FF", background: "#10143D" }
  },
  ["PURPLE" /* PURPLE */]: {
    light: { foreground: "#9E62FF", background: "#FAF5FF" },
    dark: { foreground: "#9E62FF", background: "#1A0040" }
  }
};
function getLogolessColorIndex(tokenName, numOptions) {
  const charCodes = Array.from(tokenName).map((char) => char.charCodeAt(0));
  const sum = charCodes.reduce((acc, curr) => acc + curr, 0);
  return sum % numOptions;
}
function useLogolessColorScheme(tokenName) {
  return useMemo(() => {
    const index = getLogolessColorIndex(tokenName, Object.keys(LOGOLESS_COLORS).length);
    return logolessColorSchemes[LOGOLESS_COLORS[Object.keys(LOGOLESS_COLORS)[index]]];
  }, [tokenName]);
}
export function useColorSchemeFromSeed(seed) {
  const isDarkMode = useIsDarkMode();
  const logolessColorScheme = useLogolessColorScheme(seed);
  const { foreground, background } = isDarkMode ? logolessColorScheme.dark : logolessColorScheme.light;
  return { foreground, background };
}
export function passesContrast(color, backgroundColor, contrastThreshold) {
  if (!color || color === "#000000" || color === "#FFFFFF") {
    return false;
  }
  const contrast = hex(color, backgroundColor);
  return contrast >= contrastThreshold;
}
function pickContrastPassingTokenColor(extractedColors, backgroundHex) {
  const colorsInOrder = [
    extractedColors.base,
    extractedColors.detail,
    extractedColors.secondary,
    extractedColors.primary
  ];
  for (const c of colorsInOrder) {
    if (!!c && passesContrast(c, backgroundHex, MIN_TOKEN_COLOR_CONTRAST_THRESHOLD)) {
      return c;
    }
  }
  return colorsLight.accent1;
}
export function getHoverCssFilter({
  isDarkMode = false,
  differenceFrom1 = 0.05
}) {
  return isDarkMode ? `brightness(${1 + differenceFrom1})` : `brightness(${1 - differenceFrom1})`;
}
export * from "./getContrastPassingTextColor";
export * from "./useColorsFromTokenColor";

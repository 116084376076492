"use strict";
import { AllowanceTransfer, MaxAllowanceTransferAmount, permit2Address } from "@uniswap/permit2-sdk";
import { CurrencyAmount } from "@uniswap/sdk-core";
import { useAccount } from "hooks/useAccount";
import { useEthersSigner } from "hooks/useEthersSigner";
import { useTriggerOnTransactionType } from "hooks/useTriggerOnTransactionType";
import ms from "ms";
import { useCallback, useMemo, useRef } from "react";
import { TransactionType } from "state/transactions/types";
import { trace } from "tracing/trace";
import { PERMIT2_ABI } from "uniswap/src/abis/permit2";
import { UserRejectedRequestError, toReadableError } from "utils/errors";
import { signTypedData } from "utils/signing";
import { didUserReject } from "utils/swapErrorToUserReadableMessage";
import { assume0xAddress } from "utils/wagmi";
import { useReadContract } from "wagmi";
const PERMIT_EXPIRATION = ms(`30d`);
const PERMIT_SIG_EXPIRATION = ms(`30m`);
function toDeadline(expiration) {
  return Math.floor((Date.now() + expiration) / 1e3);
}
export function usePermitAllowance(token, owner, spender) {
  const queryEnabled = !!owner && !!token?.address && !!spender;
  const { data, refetch: refetchAllowance } = useReadContract({
    address: assume0xAddress(permit2Address(token?.chainId)),
    abi: PERMIT2_ABI,
    chainId: token?.chainId,
    functionName: "allowance",
    args: queryEnabled ? [assume0xAddress(owner), assume0xAddress(token?.address), assume0xAddress(spender)] : void 0,
    query: { enabled: queryEnabled }
  });
  useTriggerOnTransactionType(TransactionType.SWAP, refetchAllowance);
  return useMemo(() => {
    if (!data) {
      return { permitAllowance: void 0, expiration: void 0, nonce: void 0 };
    }
    const [amount, expiration, nonce] = data;
    const allowance = token && amount !== void 0 ? CurrencyAmount.fromRawAmount(token, amount.toString()) : void 0;
    return { permitAllowance: allowance, expiration, nonce };
  }, [data, token]);
}
export function useUpdatePermitAllowance(token, spender, nonce, onPermitSignature) {
  const account = useAccount();
  const signer = useEthersSigner();
  const accountRef = useRef(account);
  accountRef.current = account;
  const signerRef = useRef(signer);
  signerRef.current = signer;
  return useCallback(
    () => trace({ name: "Permit2", op: "permit.permit2.signature" }, async (trace2) => {
      try {
        const account2 = accountRef.current;
        if (account2.status !== "connected") {
          throw new Error("wallet not connected");
        }
        if (!account2.chainId) {
          throw new Error("connected to an unsupported network");
        }
        if (!token) {
          throw new Error("missing token");
        }
        if (!spender) {
          throw new Error("missing spender");
        }
        if (nonce === void 0) {
          throw new Error("missing nonce");
        }
        const permit = {
          details: {
            token: token.address,
            amount: MaxAllowanceTransferAmount,
            expiration: toDeadline(PERMIT_EXPIRATION),
            nonce
          },
          spender,
          sigDeadline: toDeadline(PERMIT_SIG_EXPIRATION)
        };
        const { domain, types, values } = AllowanceTransfer.getPermitData(
          permit,
          permit2Address(token?.chainId),
          account2.chainId
        );
        const signature = await trace2.child({ name: "Sign", op: "wallet.sign" }, async () => {
          try {
            const signer2 = signerRef.current;
            if (!signer2) {
              throw new Error("missing signer");
            }
            return await signTypedData(signer2, domain, types, values);
          } catch (error) {
            if (didUserReject(error)) {
              const symbol = token?.symbol ?? "Token";
              throw new UserRejectedRequestError(`${symbol} permit allowance failed: User rejected signature`);
            } else {
              throw error;
            }
          }
        });
        onPermitSignature?.({ ...permit, signature });
        return;
      } catch (error) {
        if (error instanceof UserRejectedRequestError) {
          throw error;
        } else {
          const symbol = token?.symbol ?? "Token";
          throw toReadableError(`${symbol} permit allowance failed:`, error);
        }
      }
    }),
    [nonce, onPermitSignature, spender, token]
  );
}

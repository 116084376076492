"use strict";
import { Price } from "@uniswap/sdk-core";
import {
  TICK_SPACINGS,
  TickMath,
  encodeSqrtRatioX96,
  nearestUsableTick,
  priceToClosestTick
} from "@uniswap/v3-sdk";
import JSBI from "jsbi";
import { convertScientificNotationToNumber } from "utilities/src/format/convertScientificNotation";
export function tryParsePrice(baseToken, quoteToken, value) {
  if (!baseToken || !quoteToken || !value) {
    return void 0;
  }
  const decimalValue = convertScientificNotationToNumber(value);
  if (!decimalValue.match(/^\d*\.?\d*$/)) {
    return void 0;
  }
  const [whole, fraction] = decimalValue.split(".");
  const decimals = fraction?.length ?? 0;
  const withoutDecimals = JSBI.BigInt((whole ?? "") + (fraction ?? ""));
  return new Price(
    baseToken,
    quoteToken,
    JSBI.multiply(JSBI.BigInt(10 ** decimals), JSBI.BigInt(10 ** baseToken.decimals)),
    JSBI.multiply(withoutDecimals, JSBI.BigInt(10 ** quoteToken.decimals))
  );
}
export function tryParseTick(baseToken, quoteToken, feeAmount, value) {
  if (!baseToken || !quoteToken || !feeAmount || !value) {
    return void 0;
  }
  const price = tryParsePrice(baseToken, quoteToken, value);
  if (!price) {
    return void 0;
  }
  let tick;
  const sqrtRatioX96 = encodeSqrtRatioX96(price.numerator, price.denominator);
  if (JSBI.greaterThanOrEqual(sqrtRatioX96, TickMath.MAX_SQRT_RATIO)) {
    tick = TickMath.MAX_TICK;
  } else if (JSBI.lessThanOrEqual(sqrtRatioX96, TickMath.MIN_SQRT_RATIO)) {
    tick = TickMath.MIN_TICK;
  } else {
    tick = priceToClosestTick(price);
  }
  return nearestUsableTick(tick, TICK_SPACINGS[feeAmount]);
}

"use strict";
import { useParams } from "react-router-dom";
import { getChainInfo, UNIVERSE_CHAIN_INFO } from "uniswap/src/features/chains/chainInfo";
import { useSupportedChainId } from "uniswap/src/features/chains/hooks/useSupportedChainId";
import { CurrencyField } from "uniswap/src/types/currency";
export function searchParamToBackendName(interfaceName) {
  if (interfaceName === null) {
    return void 0;
  }
  const chain = Object.values(UNIVERSE_CHAIN_INFO).find((item) => item.interfaceName === interfaceName);
  return chain ? chain.urlParam : void 0;
}
export function isChainUrlParam(str) {
  return !!str && Object.values(UNIVERSE_CHAIN_INFO).some((chain) => chain.urlParam === str);
}
export function getChainIdFromChainUrlParam(chainUrlParam) {
  return chainUrlParam !== void 0 ? Object.values(UNIVERSE_CHAIN_INFO).find((chain) => chainUrlParam === chain.urlParam)?.id : void 0;
}
export function getChainIdFromBackendChain(backendChain) {
  return Object.values(UNIVERSE_CHAIN_INFO).find((chain) => backendChain === chain.backendChain.chain)?.id;
}
export function getChainUrlParam(chainId) {
  return getChainInfo(chainId).urlParam;
}
export function useChainIdFromUrlParam() {
  const chainName = useParams().chainName;
  const tab = useParams().tab;
  const chainId = getChainIdFromChainUrlParam(chainName ?? tab);
  const supportedChainId = useSupportedChainId(chainId);
  return supportedChainId;
}
export function getParsedChainId(parsedQs, key = CurrencyField.INPUT) {
  const chain = key === CurrencyField.INPUT ? parsedQs?.chain : parsedQs?.outputChain;
  if (!chain || typeof chain !== "string") {
    return void 0;
  }
  const chainInfo = Object.values(UNIVERSE_CHAIN_INFO).find((i) => i.interfaceName === chain);
  return chainInfo?.id;
}

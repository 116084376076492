import { forwardRef, useCallback, useMemo, useRef } from "react";
import { YStack } from "tamagui";
import { withAnimated } from "ui/src/components/factories/animated";
import { defaultHitslopInset } from "ui/src/theme";
import { isTestEnv } from "utilities/src/environment/env";
const animationProps = isTestEnv() ? {} : {
  animation: "simple",
  animateOnly: ["transform", "opacity"]
};
export const TouchableArea = forwardRef(function TouchableArea2({
  ignoreDragEvents = false,
  scaleTo,
  onPress,
  children,
  hoverable = true,
  activeOpacity = 0.75,
  pressStyle: pressStyleProp,
  hoverStyle: hoverStyleProp,
  ...restProps
}, ref) {
  const touchActivationPositionRef = useRef(null);
  const onPressHandler = useCallback(
    async (event) => {
      if (!onPress) {
        return;
      }
      if (!ignoreDragEvents) {
        const { pageX, pageY } = event.nativeEvent;
        const isDragEvent = touchActivationPositionRef.current && isDrag(touchActivationPositionRef.current.pageX, touchActivationPositionRef.current.pageY, pageX, pageY);
        if (isDragEvent) {
          return;
        }
      }
      onPress(event);
    },
    [onPress, ignoreDragEvents]
  );
  const onPressInHandler = useMemo(() => {
    return ({ nativeEvent: { pageX, pageY } }) => {
      touchActivationPositionRef.current = { pageX, pageY };
    };
  }, []);
  const pressStyle = useMemo(() => {
    return {
      opacity: activeOpacity,
      scale: scaleTo ?? 1,
      ...pressStyleProp
    };
  }, [activeOpacity, scaleTo, pressStyleProp]);
  const hoverStyle = useMemo(() => {
    if (!hoverable || !hoverStyleProp) {
      return {};
    }
    return {
      backgroundColor: "$backgroundHover",
      ...hoverStyleProp
    };
  }, [hoverable, hoverStyleProp]);
  return <YStack
    ref={ref}
    {...animationProps}
    cursor="pointer"
    hitSlop={defaultHitslopInset}
    {...restProps}
    pressStyle={pressStyle}
    hoverStyle={hoverStyle}
    onPress={onPressHandler}
    onPressIn={onPressInHandler}
  >{children}</YStack>;
});
export const AnimatedTouchableArea = withAnimated(TouchableArea);
function isDrag(activationX, activationY, releaseX, releaseY, threshold = 2) {
  const absX = Math.abs(activationX - releaseX);
  const absY = Math.abs(activationY - releaseY);
  const dragged = absX > threshold || absY > threshold;
  return dragged;
}

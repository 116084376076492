"use strict";
import { useAccount } from "hooks/useAccount";
import { useEffect } from "react";
import { useAppSelector } from "state/hooks";
import { useIsSupportedChainId } from "uniswap/src/features/chains/hooks/useSupportedChainId";
export const useOnGlobalChainSwitch = (callback) => {
  const { chainId } = useAccount();
  const isSupportedChain = useIsSupportedChainId(chainId);
  const switchingChain = useAppSelector((state) => state.wallets.switchingChain);
  useEffect(() => {
    if (isSupportedChain && chainId === switchingChain) {
      callback(chainId);
    }
  }, [callback, chainId, isSupportedChain, switchingChain]);
};

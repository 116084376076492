export const FOCUS_SCALE = 0.98;
export const PRESS_SCALE = FOCUS_SCALE;
export const defaultFocusVisibleStyle = {
  outlineColor: "$neutral3Hovered"
};
export const brandedFocusVisibleStyle = {
  outlineColor: "$accent1Hovered"
};
export const criticalFocusVisibleStyle = {
  outlineColor: "$statusCriticalHovered"
};
export const warningFocusVisibleStyle = {
  outlineColor: "$statusWarningHovered"
};
export const commonPressStyle = {
  scale: PRESS_SCALE
};

import { ApolloLink } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { getOperationName, getOperationType } from "utilities/src/logger/datadog/datadogLinkUtils";
const DATADOG_CUSTOM_HEADER_PREFIX = "_dd-custom-header";
const DATADOG_GRAPH_QL_OPERATION_NAME_HEADER = `${DATADOG_CUSTOM_HEADER_PREFIX}-graph-ql-operation-name`;
const DATADOG_GRAPH_QL_OPERATION_TYPE_HEADER = `${DATADOG_CUSTOM_HEADER_PREFIX}-graph-ql-operation-type`;
export const getDatadogApolloLink = () => {
  return new DatadogLink();
};
class DatadogLink extends ApolloLink {
  constructor() {
    super((operation, forward) => {
      const operationName = getOperationName(operation);
      const operationType = getOperationType(operation);
      operation.setContext(({ headers = {} }) => {
        const newHeaders = {
          ...headers
        };
        newHeaders[DATADOG_GRAPH_QL_OPERATION_TYPE_HEADER] = operationType;
        newHeaders[DATADOG_GRAPH_QL_OPERATION_NAME_HEADER] = operationName;
        return {
          headers: newHeaders
        };
      });
      datadogRum.addAction("graphql_operation", {
        operationType,
        operationName
      });
      return forward(operation);
    });
  }
}

"use strict";
import { CheckMark } from "components/Icons/CheckMark";
import { LoaderV3 } from "components/Icons/LoadingSpinner";
import styled, { keyframes } from "lib/styled-components";
import { useEffect, useState } from "react";
import { ThemedText } from "theme/components";
import { ExternalLink } from "theme/components/Links";
import { Flex } from "ui/src";
import { StepStatus } from "uniswap/src/components/ConfirmSwapModal/types";
export const ICON_SIZE = 24;
const ringAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
`;
const Ring = styled.div`
  position: absolute;
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  border: 1px solid ${({ $borderColor }) => $borderColor};
  border-radius: 50%;
  animation: ${({ $animation }) => $animation} 1.5s linear infinite;
`;
function RippleAnimation({ rippleColor }) {
  if (!rippleColor) {
    return null;
  }
  return <Flex data-testid="icon-ripple-animation"><Ring $borderColor={rippleColor} $animation={ringAnimation} /></Flex>;
}
function Icon({ stepStatus, icon, rippleColor }) {
  const isActive = stepStatus === StepStatus.Active;
  if (stepStatus === StepStatus.InProgress) {
    return <LoaderV3 size={`${ICON_SIZE}px`} stroke={rippleColor} fill={rippleColor} data-testid="loader-icon" />;
  }
  return <Flex>{isActive && <RippleAnimation rippleColor={rippleColor} />}<Flex
    height={ICON_SIZE}
    width={ICON_SIZE}
    filter={isActive ? "grayscale(0)" : "grayscale(1)"}
    data-testid="step-icon"
  >{icon}</Flex></Flex>;
}
function Title({
  stepStatus,
  stepDetails,
  isTimeRemaining
}) {
  switch (stepStatus) {
    case StepStatus.Preview:
      return <ThemedText.LabelSmall>{stepDetails.previewTitle}</ThemedText.LabelSmall>;
    case StepStatus.Active:
      return <ThemedText.BodySmall>{isTimeRemaining ? stepDetails.actionRequiredTitle : stepDetails.delayedStartTitle}</ThemedText.BodySmall>;
    case StepStatus.InProgress:
      return <ThemedText.BodySmall>{isTimeRemaining ? stepDetails.inProgressTitle : null}</ThemedText.BodySmall>;
    case StepStatus.Complete:
      return <ThemedText.LabelSmall>{stepDetails.previewTitle}</ThemedText.LabelSmall>;
    default:
      return null;
  }
}
const MonospacedTimer = styled(ThemedText.LabelSmall)`
  font-variant-numeric: tabular-nums;
  padding-right: 8px;
`;
function Timer({ secondsRemaining }) {
  const minutes = Math.floor(secondsRemaining / 60);
  const seconds = secondsRemaining % 60;
  const minutesText = minutes < 10 ? `0${minutes}` : minutes;
  const secondsText = seconds < 10 ? `0${seconds}` : seconds;
  const timerText = `${minutesText}:${secondsText}`;
  return <MonospacedTimer data-testid="step-timer">{timerText}</MonospacedTimer>;
}
const StyledExternalLink = styled(ExternalLink)`
  font-size: 12px;
  font-weight: 485px;
  line-height: 16px;
`;
export function Step({ stepStatus, stepDetails }) {
  const [secondsRemaining, setSecondsRemaining] = useState(null);
  useEffect(() => {
    if (stepStatus === StepStatus.Active && stepDetails?.timeToStart) {
      setSecondsRemaining(stepDetails.timeToStart);
    } else {
      setSecondsRemaining(null);
      return void 0;
    }
    const timer = setInterval(() => {
      setSecondsRemaining((prevSecondsRemaining) => {
        if (prevSecondsRemaining && prevSecondsRemaining > 0) {
          return prevSecondsRemaining - 1;
        }
        clearInterval(timer);
        return 0;
      });
    }, 1e3);
    return () => clearInterval(timer);
  }, [stepStatus, stepDetails.timeToStart]);
  return <Flex row pr="$spacing16" justifyContent="space-between"><Flex row centered gap="$gap12" height={40} px="$spacing8" py="$spacing16"><Icon stepStatus={stepStatus} icon={stepDetails.icon} rippleColor={stepDetails.rippleColor} /><Flex><Title
    stepStatus={stepStatus}
    stepDetails={stepDetails}
    isTimeRemaining={secondsRemaining === null || secondsRemaining > 0}
  />{stepStatus === StepStatus.Active && stepDetails.learnMoreLinkHref && <StyledExternalLink href={stepDetails.learnMoreLinkHref || ""}>{stepDetails.learnMoreLinkText}</StyledExternalLink>}</Flex></Flex>{secondsRemaining !== null && <Timer secondsRemaining={secondsRemaining} />}{stepStatus === StepStatus.Complete && <CheckMark />}</Flex>;
}

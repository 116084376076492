"use strict";
import { Group } from "@visx/group";
import { LinePath } from "@visx/shape";
import React from "react";
function LineChart({
  data,
  getX,
  getY,
  marginTop,
  curve,
  color,
  strokeWidth,
  width,
  height,
  children
}) {
  return <svg width={width} height={height}><Group top={marginTop}><LinePath curve={curve} stroke={color} strokeWidth={strokeWidth} data={data} x={getX} y={getY} /></Group>{children}</svg>;
}
export default React.memo(LineChart);

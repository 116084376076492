import { Path, Svg } from "react-native-svg";
import { createIcon } from "../factories/createIcon";
export const [Passkey, AnimatedPasskey] = createIcon({
  name: "Passkey",
  getIcon: (props) => <Svg viewBox="0 0 24 24" fill="none" {...props}><Path
    d="M16.4303 8.67684C16.2913 8.71327 16.1049 8.77702 16.0162 8.81648C15.4246 9.08059 14.8448 9.63916 14.5608 10.2281C14.2177 10.9324 14.1733 11.8886 14.4514 12.605C14.6969 13.2365 15.1406 13.7677 15.6878 14.0834L15.8978 14.2048L15.9008 16.4816L15.9067 18.7584L16.522 19.3807L17.1402 20L18.1607 18.9557C18.7228 18.3789 19.1813 17.8963 19.1813 17.878C19.1813 17.8629 18.9121 17.5714 18.5837 17.2345L17.9833 16.6182L18.3855 16.2023C18.9062 15.662 19.1813 15.3645 19.1813 15.3341C19.1813 15.322 18.986 15.1065 18.7464 14.8545C18.5068 14.6025 18.2938 14.3748 18.2761 14.3445C18.2436 14.299 18.2613 14.2807 18.421 14.1897C19.1043 13.8102 19.5569 13.3002 19.8261 12.6111C19.971 12.2377 20.0361 11.6336 19.9799 11.1266C19.8646 10.049 19.0807 9.08362 18.0365 8.72845C17.6135 8.58273 16.8562 8.55845 16.4303 8.67684ZM17.4153 9.92755C17.6934 10.0247 17.998 10.4922 17.9566 10.7593C17.93 10.9293 17.7673 11.2359 17.649 11.3361C17.4183 11.5334 17.0456 11.5729 16.7823 11.4333C16.3534 11.2056 16.2084 10.6379 16.4747 10.2433C16.5575 10.1188 16.7527 9.96701 16.8888 9.91541C17.0219 9.86683 17.2585 9.87291 17.4153 9.92755Z"
    fill={"currentColor"}
    fillRule="evenodd"
    clipRule="evenodd"
  /><Path
    d="M9.36733 4.02012C9.28451 4.03529 9.10999 4.08386 8.98575 4.1294C7.82325 4.53011 6.87964 5.56832 6.55721 6.80385C6.48622 7.07706 6.4803 7.15296 6.47734 7.72367C6.47439 8.14563 6.48622 8.40063 6.51284 8.51295C6.85597 9.96705 7.83508 11.0265 9.19577 11.4181C9.41762 11.4819 9.54777 11.497 9.9826 11.5092C10.7961 11.5335 11.1362 11.4667 11.7781 11.154C12.3342 10.8808 12.7838 10.4953 13.1506 9.96705C13.3991 9.60884 13.5204 9.37509 13.6505 9.0017C13.8044 8.54634 13.8369 8.29135 13.8162 7.57796C13.7985 6.86457 13.7511 6.65207 13.4701 6.06921C13.0116 5.11297 12.2662 4.45726 11.2368 4.10815L10.941 4.00797L10.2311 4.0019C9.84062 3.99583 9.45312 4.00494 9.36733 4.02012Z"
    fill={"currentColor"}
  /><Path
    d="M6.56435 13.4247C7.2654 13.0271 7.88954 12.8601 8.82724 12.8237C9.14374 12.8115 9.96312 12.8024 10.6464 12.8085C12.0722 12.8176 12.2615 12.8389 12.9685 13.0756L13.3678 13.2062L13.5482 13.528C13.7938 13.9651 13.9564 14.1746 14.3499 14.5753L14.6841 14.9153V19.5326H4.00566L4.0027 18.9163C3.99087 17.4622 4.01453 17.0828 4.14173 16.5879C4.48486 15.2522 5.36339 14.1047 6.56435 13.4247Z"
    fill={"currentColor"}
  /></Svg>,
  defaultFill: "#222222"
});

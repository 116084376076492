"use strict";
import { Percent } from "@uniswap/sdk-core";
import Row from "components/deprecated/Row";
import styled, { css } from "lib/styled-components";
import { X } from "react-feather";
import { Trans } from "react-i18next";
import { ThemedText } from "theme/components";
import { ClickableStyle } from "theme/components/styles";
import { useFormatter } from "utils/formatNumbers";
const containerBorderCss = css`
  border-radius: 999px;
  border: 1px solid ${({ theme }) => theme.surface3};
`;
const highlightedBorderCss = css`
  border-radius: 999px 0px 0px 999px;
  border-top: 1px solid ${({ theme }) => theme.surface3};
  border-bottom: 1px solid ${({ theme }) => theme.surface3};
  border-left: 1px solid ${({ theme }) => theme.surface3};
  border-right: 0px;
`;
const Container = styled.button`
  color: ${({ theme, $selected, $disabled, $highlighted }) => {
  if ($highlighted) {
    return theme.neutral1;
  }
  if ($selected && !$disabled) {
    return theme.neutral1;
  }
  return theme.neutral2;
}};
  padding: 2px 8px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, $highlighted, $selected }) => {
  if ($highlighted || $selected) {
    return theme.surface3;
  }
  return "unset";
}};
  ${({ $highlighted }) => $highlighted ? highlightedBorderCss : containerBorderCss};
  ${({ $disabled }) => !$disabled && ClickableStyle};
`;
const HighlightedContainerXButton = styled.button`
  ${ClickableStyle}
  background-color: ${({ theme }) => theme.surface3};
  color: ${({ theme }) => theme.neutral2};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 6px 6px 6px 4px;
  height: 28px;
  border-radius: 0px 999px 999px 0px;
  border-top: 1px solid ${({ theme }) => theme.surface3};
  border-bottom: 1px solid ${({ theme }) => theme.surface3};
  border-right: 1px solid ${({ theme }) => theme.surface3};
  border-left: 1px solid transparent;
`;
export function LimitPresetPriceButton({
  priceAdjustmentPercentage,
  selected,
  disabled,
  onSelect
}) {
  const { formatPercent } = useFormatter();
  const sign = priceAdjustmentPercentage > 0 ? "+" : "-";
  return <Container
    $selected={selected}
    $disabled={disabled}
    $highlighted={false}
    onClick={() => !disabled && onSelect(priceAdjustmentPercentage)}
  >{priceAdjustmentPercentage === 0 ? <ThemedText.BodySecondary fontWeight={535} color="inherit"><Trans i18nKey="common.market.label" /></ThemedText.BodySecondary> : <ThemedText.BodySecondary fontWeight={535} color="inherit">{sign}{formatPercent(new Percent(Math.abs(priceAdjustmentPercentage), 100))}</ThemedText.BodySecondary>}</Container>;
}
export function LimitCustomMarketPriceButton({
  customAdjustmentPercentage,
  selected,
  disabled,
  onSelect
}) {
  const onSetAdjustmentPercentage = () => !disabled && onSelect(0);
  const { formatPercent } = useFormatter();
  return <Row width="unset" gap="1px"><Container
    $selected={selected}
    $disabled={disabled}
    $highlighted={customAdjustmentPercentage !== void 0}
    onClick={onSetAdjustmentPercentage}
  >{!customAdjustmentPercentage ? <ThemedText.BodySecondary color="inherit" fontWeight={535}><Trans i18nKey="common.market.label" /></ThemedText.BodySecondary> : <ThemedText.BodySecondary color="inherit" fontWeight={535}>{customAdjustmentPercentage > 0 ? "+" : ""}{formatPercent(new Percent(customAdjustmentPercentage, 100))}</ThemedText.BodySecondary>}</Container>{customAdjustmentPercentage && <HighlightedContainerXButton onClick={onSetAdjustmentPercentage}><X size={16} /></HighlightedContainerXButton>}</Row>;
}

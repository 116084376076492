"use strict";
import { CurrencyAmount, Fraction } from "@uniswap/sdk-core";
import { parseUnits } from "ethers/lib/utils";
import JSBI from "jsbi";
import { useUSDCValue } from "uniswap/src/features/transactions/hooks/useUSDCPrice";
const WARNING_THRESHOLD = new Fraction(5, 100);
const DECIMAL_SCALAR = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18));
function useMarketPrice(baseCurrency, quoteCurrency) {
  const baseCurrencyUSDPrice = useUSDCValue(
    baseCurrency ? CurrencyAmount.fromRawAmount(baseCurrency, JSBI.BigInt(parseUnits("1", baseCurrency?.decimals))) : void 0
  );
  const quoteCurrencyUSDPrice = useUSDCValue(
    quoteCurrency ? CurrencyAmount.fromRawAmount(quoteCurrency, JSBI.BigInt(parseUnits("1", quoteCurrency?.decimals))) : void 0
  );
  if (!baseCurrencyUSDPrice || !quoteCurrencyUSDPrice) {
    return void 0;
  }
  const marketPrice = new Fraction(
    baseCurrencyUSDPrice.multiply(DECIMAL_SCALAR).toFixed(0),
    quoteCurrencyUSDPrice.multiply(DECIMAL_SCALAR).toFixed(0)
  );
  return marketPrice;
}
export function useIsPoolOutOfSync(poolPrice) {
  const marketPrice = useMarketPrice(poolPrice?.baseCurrency, poolPrice?.quoteCurrency);
  if (!poolPrice || !marketPrice) {
    return false;
  }
  const scaledMarketPrice = JSBI.BigInt(marketPrice.multiply(DECIMAL_SCALAR).toFixed(0));
  const scaledPoolPrice = JSBI.BigInt(
    poolPrice.quote(
      CurrencyAmount.fromRawAmount(
        poolPrice.baseCurrency,
        JSBI.BigInt(parseUnits("1", poolPrice.baseCurrency?.decimals))
      )
    ).multiply(DECIMAL_SCALAR).toFixed(0)
  );
  const difference = JSBI.lessThan(scaledMarketPrice, scaledPoolPrice) ? JSBI.subtract(scaledPoolPrice, scaledMarketPrice) : JSBI.subtract(scaledMarketPrice, scaledPoolPrice);
  const divergence = new Fraction(difference, scaledMarketPrice);
  return divergence.greaterThan(WARNING_THRESHOLD);
}

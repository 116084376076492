import { colorsDark, colorsLight } from "ui/src/theme/color/colors";
const { none: darkTransparent, ...tamaguiColorsDark } = colorsDark;
const { none: lightTransparent, ...tamaguiColorsLight } = colorsLight;
const light = {
  // Uniswap Design System
  ...tamaguiColorsLight,
  transparent: lightTransparent,
  // Tamagui Theme
  // Tamagui components expect the following
  background: colorsLight.surface1,
  backgroundHover: colorsLight.surface2,
  backgroundPress: colorsLight.surface2,
  backgroundFocus: colorsLight.surface2,
  borderColor: colorsLight.none,
  borderColorHover: colorsLight.none,
  borderColorFocus: colorsLight.none,
  outlineColor: colorsLight.none,
  color: colorsLight.neutral1,
  colorHover: colorsLight.accent1,
  colorPress: colorsLight.accent1,
  colorFocus: colorsLight.accent1,
  shadowColor: "rgba(0,0,0,0.15)",
  shadowColorHover: "rgba(0,0,0,0.2)"
};
const dark = {
  ...tamaguiColorsDark,
  transparent: darkTransparent,
  background: colorsDark.surface1,
  backgroundHover: colorsDark.surface2,
  backgroundPress: colorsDark.surface2,
  backgroundFocus: colorsDark.surface2,
  borderColor: colorsDark.none,
  borderColorHover: colorsDark.none,
  borderColorFocus: colorsDark.none,
  outlineColor: colorsDark.none,
  color: colorsDark.neutral1,
  colorHover: colorsDark.accent1,
  colorPress: colorsDark.accent1,
  colorFocus: colorsDark.accent1,
  shadowColor: "rgba(0,0,0,0.4)",
  shadowColorHover: "rgba(0,0,0,0.5)"
};
const allThemes = {
  dark,
  light
};
export const themes = allThemes;

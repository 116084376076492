"use strict";
import { atom } from "jotai";
export var MenuState = /* @__PURE__ */ ((MenuState2) => {
  MenuState2["DEFAULT"] = "default";
  MenuState2["SETTINGS"] = "settings";
  MenuState2["LANGUAGE_SETTINGS"] = "language_settings";
  MenuState2["LOCAL_CURRENCY_SETTINGS"] = "local_currency_settings";
  MenuState2["LIMITS"] = "limits";
  MenuState2["POOLS"] = "pools";
  MenuState2["PASSKEYS"] = "passkeys";
  return MenuState2;
})(MenuState || {});
export const miniPortfolioMenuStateAtom = atom("default" /* DEFAULT */);

"use strict";
import { css } from "lib/styled-components";
export const EllipsisStyle = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const EllipsisTamaguiStyle = {
  "$platform-web": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
};

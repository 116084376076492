export const getOperationName = (operation) => {
  if (operation.operationName) {
    return operation.operationName;
  }
  return null;
};
const getOperationDefinitionNode = (definition) => {
  return definition.kind === "OperationDefinition" && !!definition.operation;
};
export const getOperationType = (operation) => {
  try {
    return operation.query.definitions.filter(getOperationDefinitionNode).map((operationDefinitionNode) => {
      return operationDefinitionNode.operation;
    })[0] || null;
  } catch (e) {
    return null;
  }
};

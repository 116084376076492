"use strict";
import styled, { css, keyframes } from "styled-components";
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const SpinnerCss = css`
  animation: 2s ${rotate} linear infinite;
`;
const Spinner = styled.img`
  ${SpinnerCss}
  width: 16px;
  height: 16px;
`;
export const SpinnerSVG = styled.svg`
  ${SpinnerCss}
`;
export const CustomLightSpinner = styled(Spinner)`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`;

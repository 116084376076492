import { cloneElement, memo } from "react";
import { useStyle } from "tamagui";
import { CustomButtonText } from "ui/src/components/buttons/Button/components/CustomButtonText/CustomButtonText";
import { useIconSizes } from "ui/src/components/buttons/Button/hooks/useIconSizes";
const _ThemedIcon = ({
  children,
  size = "medium",
  variant,
  isDisabled,
  emphasis,
  typeOfButton,
  "custom-background-color": customBackgroundColor
}) => {
  const iconSizes = useIconSizes(typeOfButton);
  const { color, "$group-item-hover": groupItemHover } = useStyle(
    { variant, emphasis, isDisabled, "custom-background-color": customBackgroundColor },
    {
      forComponent: CustomButtonText
    }
  );
  if (!children) {
    return null;
  }
  const finalGroupItemHover = children.props?.["$group-item-hover"] ?? groupItemHover;
  const width = iconSizes[size];
  const height = width;
  return cloneElement(children, {
    color: children.props?.color ?? color,
    width,
    height,
    "$group-item-hover": finalGroupItemHover
  });
};
const ThemedIcon = memo(_ThemedIcon);
export { ThemedIcon };

"use strict";
import { BigNumber } from "@ethersproject/bignumber";
import { ProtocolVersion } from "@uniswap/client-pools/dist/pools/v1/types_pb";
import { CurrencyAmount } from "@uniswap/sdk-core";
import { Pool as PoolV3, TICK_SPACINGS, TickMath as TickMathV3, tickToPrice } from "@uniswap/v3-sdk";
import { Pool as PoolV4 } from "@uniswap/v4-sdk";
import { ChartModel } from "components/Charts/ChartModel";
import { LiquidityBarSeries } from "components/Charts/LiquidityChart/liquidity-bar-series";
import { ZERO_ADDRESS } from "constants/misc";
import { usePoolActiveLiquidity } from "hooks/usePoolTickData";
import JSBI from "jsbi";
import { useEffect, useState } from "react";
import { NumberType, useFormatter } from "utils/formatNumbers";
export class LiquidityBarChartModel extends ChartModel {
  series;
  activeTick;
  constructor(chartDiv, params) {
    super(chartDiv, params);
    this.series = this.api.addCustomSeries(new LiquidityBarSeries(params));
    this.series.setData(this.data);
    this.updateOptions(params);
    this.fitContent();
  }
  updateOptions(params) {
    super.updateOptions(params, {
      localization: {
        locale: params.locale
      },
      rightPriceScale: {
        visible: false,
        borderVisible: false,
        scaleMargins: {
          top: 0.35,
          bottom: 0
        },
        autoScale: true
      },
      timeScale: {
        visible: false,
        fixLeftEdge: true,
        fixRightEdge: true,
        borderVisible: false
      },
      crosshair: {
        horzLine: {
          visible: false,
          labelVisible: false
        },
        vertLine: {
          visible: false,
          labelVisible: false
        }
      },
      grid: {
        vertLines: {
          visible: false
        },
        horzLines: {
          visible: false
        }
      }
    });
    const { data, activeTick } = params;
    this.activeTick = activeTick;
    if (this.data !== data) {
      this.data = data;
      this.series.setData(data);
      this.fitContent();
    }
    this.series.applyOptions({
      priceFormat: {
        type: "volume"
      },
      priceLineVisible: false,
      lastValueVisible: false
    });
    this.series.applyOptions(params);
  }
  onSeriesHover(hoverData) {
    super.onSeriesHover(hoverData);
    const updatedOptions = { hoveredTick: hoverData?.item.tick ?? this.activeTick };
    this.series.applyOptions(updatedOptions);
  }
  activeTickIndex() {
    return this.data.findIndex((bar) => bar.tick === this.activeTick);
  }
  fitContent() {
    const length = this.data.length;
    const activeTickIndex = this.data.findIndex((bar) => bar.tick === this.activeTick);
    const midPoint = activeTickIndex !== -1 ? activeTickIndex : length / 2;
    this.api.timeScale().setVisibleLogicalRange({ from: Math.max(midPoint - 50, 0), to: Math.min(midPoint + 50, this.data.length) });
  }
}
const MAX_UINT128 = BigNumber.from(2).pow(128).sub(1);
function maxAmount(token) {
  return CurrencyAmount.fromRawAmount(token, MAX_UINT128.toString());
}
async function calculateActiveRangeTokensLocked(token0, token1, feeTier, tick, poolData) {
  if (!poolData.currentTick || !poolData.sqrtPriceX96 || !poolData.liquidity) {
    return void 0;
  }
  try {
    const liqGross = JSBI.greaterThan(tick.liquidityNet, JSBI.BigInt(0)) ? tick.liquidityNet : JSBI.multiply(tick.liquidityNet, JSBI.BigInt("-1"));
    const mockTicks = [
      {
        index: tick.tick,
        liquidityGross: liqGross,
        liquidityNet: JSBI.multiply(tick.liquidityNet, JSBI.BigInt("-1"))
      },
      {
        index: tick.tick + TICK_SPACINGS[feeTier],
        liquidityGross: liqGross,
        liquidityNet: tick.liquidityNet
      }
    ];
    const pool1 = new PoolV3(
      token0,
      token1,
      feeTier,
      poolData.sqrtPriceX96,
      tick.liquidityActive,
      poolData.currentTick,
      mockTicks
    );
    const bottomOfRangePrice = TickMathV3.getSqrtRatioAtTick(mockTicks[0].index);
    const token1Amount = (await pool1.getOutputAmount(maxAmount(token0), bottomOfRangePrice))[0];
    const amount0Locked = parseFloat(tick.sdkPrice.invert().quote(token1Amount).toExact());
    const topOfRangePrice = TickMathV3.getSqrtRatioAtTick(mockTicks[1].index);
    const token0Amount = (await pool1.getOutputAmount(maxAmount(token1), topOfRangePrice))[0];
    const amount1Locked = parseFloat(tick.sdkPrice.quote(token0Amount).toExact());
    return { amount0Locked, amount1Locked };
  } catch {
    return { amount0Locked: 0, amount1Locked: 0 };
  }
}
export async function calculateTokensLockedV3(token0, token1, feeTier, tick) {
  try {
    const tickSpacing = TICK_SPACINGS[feeTier];
    const liqGross = JSBI.greaterThan(tick.liquidityNet, JSBI.BigInt(0)) ? tick.liquidityNet : JSBI.multiply(tick.liquidityNet, JSBI.BigInt("-1"));
    const sqrtPriceX96 = TickMathV3.getSqrtRatioAtTick(tick.tick);
    const mockTicks = [
      {
        index: tick.tick,
        liquidityGross: liqGross,
        liquidityNet: JSBI.multiply(tick.liquidityNet, JSBI.BigInt("-1"))
      },
      {
        index: tick.tick + TICK_SPACINGS[feeTier],
        liquidityGross: liqGross,
        liquidityNet: tick.liquidityNet
      }
    ];
    const pool = new PoolV3(token0, token1, Number(feeTier), sqrtPriceX96, tick.liquidityActive, tick.tick, mockTicks);
    const nextSqrtX96 = TickMathV3.getSqrtRatioAtTick(tick.tick - tickSpacing);
    const maxAmountToken0 = CurrencyAmount.fromRawAmount(token0, MAX_UINT128.toString());
    const token1Amount = (await pool.getOutputAmount(maxAmountToken0, nextSqrtX96))[0];
    const amount0Locked = parseFloat(tick.sdkPrice.invert().quote(token1Amount).toExact());
    const amount1Locked = parseFloat(token1Amount.toExact());
    return { amount0Locked, amount1Locked };
  } catch {
    return { amount0Locked: 0, amount1Locked: 0 };
  }
}
export async function calculateTokensLockedV4(token0, token1, feeTier, tickSpacing, hooks, tick) {
  try {
    const liqGross = JSBI.greaterThan(tick.liquidityNet, JSBI.BigInt(0)) ? tick.liquidityNet : JSBI.multiply(tick.liquidityNet, JSBI.BigInt("-1"));
    const sqrtPriceX96 = TickMathV3.getSqrtRatioAtTick(tick.tick);
    const mockTicks = [
      {
        index: tick.tick,
        liquidityGross: liqGross,
        liquidityNet: JSBI.multiply(tick.liquidityNet, JSBI.BigInt("-1"))
      },
      {
        index: tick.tick + TICK_SPACINGS[feeTier],
        liquidityGross: liqGross,
        liquidityNet: tick.liquidityNet
      }
    ];
    const pool = new PoolV4(
      token0,
      token1,
      Number(feeTier),
      tickSpacing,
      hooks,
      sqrtPriceX96,
      tick.liquidityActive,
      tick.tick,
      mockTicks
    );
    const nextSqrtX96 = TickMathV3.getSqrtRatioAtTick(tick.tick - tickSpacing);
    const maxAmountToken0 = CurrencyAmount.fromRawAmount(token0, MAX_UINT128.toString());
    const token1Amount = (await pool.getOutputAmount(maxAmountToken0, nextSqrtX96))[0];
    const amount0Locked = parseFloat(tick.sdkPrice.invert().quote(token1Amount).toExact());
    const amount1Locked = parseFloat(token1Amount.toExact());
    return { amount0Locked, amount1Locked };
  } catch {
    return { amount0Locked: 0, amount1Locked: 0 };
  }
}
export function useLiquidityBarData({
  tokenA,
  tokenB,
  feeTier,
  isReversed,
  chainId,
  version,
  tickSpacing,
  hooks,
  poolId
}) {
  const { formatNumber, formatPrice } = useFormatter();
  const activePoolData = usePoolActiveLiquidity({
    currencyA: tokenA,
    currencyB: tokenB,
    feeAmount: feeTier,
    version,
    poolId,
    chainId,
    tickSpacing: tickSpacing ?? TICK_SPACINGS[feeTier],
    hooks
  });
  const [tickData, setTickData] = useState();
  useEffect(() => {
    async function formatData() {
      const ticksProcessed = activePoolData.data;
      if (!ticksProcessed) {
        return;
      }
      let activeRangePercentage = void 0;
      let activeRangeIndex = void 0;
      const barData = [];
      for (let index = 0; index < ticksProcessed.length; index++) {
        const t = ticksProcessed[index];
        const fakeTime = isReversed ? index * 1e3 : (ticksProcessed.length - index) * 1e3;
        const isActive = activePoolData.activeTick === t.tick;
        let price0 = t.sdkPrice;
        let price1 = t.sdkPrice.invert();
        if (isActive && activePoolData.activeTick && activePoolData.currentTick) {
          activeRangeIndex = index;
          activeRangePercentage = (activePoolData.currentTick - t.tick) / TICK_SPACINGS[feeTier];
          price0 = tickToPrice(tokenA, tokenB, t.tick);
          price1 = price0.invert();
        }
        const { amount0Locked, amount1Locked } = await (version === ProtocolVersion.V3 ? calculateTokensLockedV3(tokenA, tokenB, feeTier, t) : calculateTokensLockedV4(
          tokenA,
          tokenB,
          feeTier,
          tickSpacing ?? TICK_SPACINGS[feeTier],
          hooks ?? ZERO_ADDRESS,
          t
        ));
        barData.push({
          tick: t.tick,
          liquidity: parseFloat(t.liquidityActive.toString()),
          price0: formatPrice({ price: price0, type: NumberType.SwapDetailsAmount }),
          price1: formatPrice({ price: price1, type: NumberType.SwapDetailsAmount }),
          time: fakeTime,
          amount0Locked,
          amount1Locked
        });
      }
      barData?.map((entry, i) => {
        if (i > 0) {
          barData[i - 1].amount0Locked = entry.amount0Locked;
          barData[i - 1].amount1Locked = entry.amount1Locked;
        }
      });
      const activeRangeData = activeRangeIndex !== void 0 ? barData[activeRangeIndex] : void 0;
      if (activeRangeIndex !== void 0 && activeRangeData) {
        const activeTickTvl = await calculateActiveRangeTokensLocked(
          tokenA,
          tokenB,
          feeTier,
          ticksProcessed[activeRangeIndex],
          activePoolData
        );
        barData[activeRangeIndex] = { ...activeRangeData, ...activeTickTvl };
      }
      if (!isReversed) {
        barData.reverse();
      }
      setTickData({ barData: barData.filter((t) => t.liquidity > 0), activeRangeData, activeRangePercentage });
    }
    formatData();
  }, [activePoolData, tokenA, tokenB, formatNumber, formatPrice, isReversed, feeTier, version, tickSpacing, hooks]);
  return { tickData, activeTick: activePoolData.activeTick, loading: activePoolData.isLoading || !tickData };
}

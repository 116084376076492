"use strict";
import { CurrencyAmount } from "@uniswap/sdk-core";
export function getCrosshairProps(color, { yCoordinate, xCoordinate }) {
  return {
    position: "absolute",
    left: xCoordinate - 3,
    top: yCoordinate - 3,
    // Center the crosshair vertically on the price line.
    width: 6,
    height: 6,
    borderRadius: "$roundedFull",
    backgroundColor: color
  };
}
export function isEffectivelyInfinity(value) {
  return Math.abs(value) >= 1e20 || Math.abs(value) <= 1e-20;
}
export function priceToNumber(price, defaultValue) {
  const baseCurrency = price?.baseCurrency;
  if (!baseCurrency) {
    return defaultValue;
  }
  const sigFigs = Boolean(baseCurrency.decimals) && baseCurrency.decimals > 0 ? baseCurrency.decimals : 6;
  const numPrice = Number(
    price.quote(CurrencyAmount.fromRawAmount(baseCurrency, Math.pow(10, baseCurrency.decimals)))?.toSignificant(sigFigs) ?? 0
  );
  if (isEffectivelyInfinity(numPrice)) {
    return defaultValue;
  }
  return numPrice;
}

"use strict";
import { ReactComponent as TooltipTriangle } from "assets/svg/tooltip_triangle.svg";
import useCopyClipboard from "hooks/useCopyClipboard";
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { CheckCircle, Copy } from "react-feather";
import { Trans } from "react-i18next";
import { ClickableTamaguiStyle, EllipsisTamaguiStyle } from "theme/components/styles";
import { Flex, Text, isTouchable } from "ui/src";
const TOOLTIP_WIDTH = 60;
function Tooltip({ isCopyContractTooltip, tooltipX }) {
  return <Flex
    alignItems="center"
    position="absolute"
    left={isCopyContractTooltip && tooltipX ? `${tooltipX - TOOLTIP_WIDTH / 2}px` : "50%"}
    transform="translate(5px, 32px)"
    zIndex="$tooltip"
  ><TooltipTriangle path="black" /><Text
    color="$white"
    variant="body3"
    borderRadius="$rounded8"
    backgroundColor="$black"
    textAlign="center"
    justifyContent="center"
    width={!isCopyContractTooltip ? `${TOOLTIP_WIDTH}px` : "auto"}
    height={!isCopyContractTooltip ? "32px" : "auto"}
    lineHeight={!isCopyContractTooltip ? "32px" : "auto"}
  ><Trans i18nKey="common.copied" /></Text></Flex>;
}
export function CopyToClipboard({ toCopy, children }) {
  const [isCopied, setCopied] = useCopyClipboard();
  const copy = useCallback(() => {
    setCopied(toCopy);
  }, [toCopy, setCopied]);
  return <Flex
    row
    onPress={copy}
    justifyContent="center"
    alignItems="center"
    {...ClickableTamaguiStyle}
    $platform-web={{
      textDecoration: "none"
    }}
  >{children}{isCopied && <Tooltip isCopyContractTooltip={false} />}</Flex>;
}
function isEllipsisActive(element) {
  return Boolean(element && element.offsetWidth < element.scrollWidth);
}
export const CopyHelper = forwardRef(
  ({
    InitialIcon = Copy,
    CopiedIcon = (props) => <CheckCircle {...props} color="var(--statusSuccess)" strokeWidth={1.5} />,
    toCopy,
    color,
    fontSize,
    iconSize = 20,
    gap = 4,
    iconPosition = "left",
    iconColor = "currentColor",
    children
  }, ref) => {
    const [isCopied, setCopied] = useCopyClipboard();
    const copy = useCallback(() => {
      setCopied(toCopy);
    }, [toCopy, setCopied]);
    useImperativeHandle(ref, () => ({
      forceCopy() {
        copy();
      }
    }));
    const textRef = useRef(null);
    const isEllipsis = isEllipsisActive(textRef.current);
    const displayGap = isEllipsis ? gap - 4 : gap;
    const [isHover, setIsHover] = useState(false);
    const onHover = useCallback(() => setIsHover(true), []);
    const offHover = useCallback(() => setIsHover(false), []);
    const showIcon = Boolean(iconPosition === "left" || isHover || isTouchable || isCopied);
    const Icon2 = isCopied ? CopiedIcon : showIcon ? InitialIcon : null;
    const offset = showIcon ? gap + iconSize : 0;
    return <Flex
      row
      onPress={copy}
      gap={displayGap}
      onMouseEnter={onHover}
      onMouseLeave={offHover}
      {...ClickableTamaguiStyle}
      alignItems="center"
      $platform-web={{
        color: color ?? "inherit"
      }}
    >{iconPosition === "left" && Icon2 && <Icon2 size={iconSize} strokeWidth={1.5} color={iconColor} />}<Flex
      ref={textRef}
      maxWidth={`calc(100% - ${offset + "px"})`}
      {...EllipsisTamaguiStyle}
      $platform-web={{
        fontSize: fontSize ? fontSize : "inherit"
      }}
    >{isCopied && iconPosition === "left" ? <Text><Trans i18nKey="common.copied" /></Text> : children}</Flex><Flex $platform-web={{ clear: "both" }} />{iconPosition === "right" && Icon2 && <Icon2 size={iconSize} strokeWidth={1.5} color={iconColor} />}</Flex>;
  }
);
CopyHelper.displayName = "CopyHelper";

"use strict";
export function isStackedHistogramData(data) {
  return data.values !== void 0;
}
export function getCumulativeSum(data) {
  return isStackedHistogramData(data) ? Object.values(data.values).filter((value) => value !== void 0).reduce((sum, curr) => sum += curr, 0) : data.value;
}
export function getCumulativeVolume(data) {
  return data.reduce((sum, curr) => sum += getCumulativeSum(curr), 0);
}
export function positionsBox(position1Media, position2Media, pixelRatio) {
  const scaledPosition1 = Math.round(pixelRatio * position1Media);
  const scaledPosition2 = Math.round(pixelRatio * position2Media);
  return {
    position: Math.min(scaledPosition1, scaledPosition2),
    length: Math.abs(scaledPosition2 - scaledPosition1)
  };
}
const alignToMinimalWidthLimit = 4;
const showSpacingMinimalBarWidth = 1;
function columnSpacing(barSpacingMedia, horizontalPixelRatio) {
  return Math.ceil(barSpacingMedia * horizontalPixelRatio) <= showSpacingMinimalBarWidth ? 0 : Math.max(1, Math.floor(horizontalPixelRatio));
}
function desiredColumnWidth(barSpacingMedia, horizontalPixelRatio, spacing) {
  return Math.round(barSpacingMedia * horizontalPixelRatio) - (spacing ?? columnSpacing(barSpacingMedia, horizontalPixelRatio));
}
function columnCommon(barSpacingMedia, horizontalPixelRatio) {
  const spacing = columnSpacing(barSpacingMedia, horizontalPixelRatio);
  const columnWidthBitmap = desiredColumnWidth(barSpacingMedia, horizontalPixelRatio, spacing);
  const shiftLeft = columnWidthBitmap % 2 === 0;
  const columnHalfWidthBitmap = (columnWidthBitmap - (shiftLeft ? 0 : 1)) / 2;
  return {
    spacing,
    shiftLeft,
    columnHalfWidthBitmap,
    horizontalPixelRatio
  };
}
function calculateColumnPosition(xMedia, columnData, previousPosition) {
  const xBitmapUnRounded = xMedia * columnData.horizontalPixelRatio;
  const xBitmap = Math.round(xBitmapUnRounded);
  const xPositions = {
    left: xBitmap - columnData.columnHalfWidthBitmap,
    right: xBitmap + columnData.columnHalfWidthBitmap - (columnData.shiftLeft ? 1 : 0),
    shiftLeft: xBitmap > xBitmapUnRounded
  };
  const expectedAlignmentShift = columnData.spacing + 1;
  if (previousPosition) {
    if (xPositions.left - previousPosition.right !== expectedAlignmentShift) {
      if (previousPosition.shiftLeft) {
        previousPosition.right = xPositions.left - expectedAlignmentShift;
      } else {
        xPositions.left = previousPosition.right + expectedAlignmentShift;
      }
    }
  }
  return xPositions;
}
export function calculateColumnPositionsInPlace(items, barSpacingMedia, horizontalPixelRatio, startIndex, endIndex) {
  const common = columnCommon(barSpacingMedia, horizontalPixelRatio);
  let previous = void 0;
  for (let i = startIndex; i < Math.min(endIndex, items.length); i++) {
    if (previous?.x && items[i].x - previous?.x > barSpacingMedia) {
      previous = void 0;
    }
    items[i].column = calculateColumnPosition(items[i].x, common, previous?.column);
    previous = items[i];
  }
  const minColumnWidth = items.reduce(
    (smallest, item, index) => {
      if (!item.column || index < startIndex || index > endIndex) {
        return smallest;
      }
      if (item.column.right < item.column.left) {
        item.column.right = item.column.left;
      }
      const width = item.column.right - item.column.left + 1;
      return Math.min(smallest, width);
    },
    Math.ceil(barSpacingMedia * horizontalPixelRatio)
  );
  if (common.spacing > 0 && minColumnWidth < alignToMinimalWidthLimit) {
    ;
    items.forEach((item, index) => {
      if (!item.column || index < startIndex || index > endIndex) {
        return void 0;
      }
      const width = item.column.right - item.column.left + 1;
      if (width <= minColumnWidth) {
        return item;
      }
      if (item.column.shiftLeft) {
        item.column.right -= 1;
      } else {
        item.column.left += 1;
      }
      return item.column;
    });
  }
}

"use strict";
import {
  AnimatedEntranceConfirmationIcon,
  AnimatedEntranceSubmittedIcon,
  LoadingIndicatorOverlay,
  LogoContainer
} from "components/AccountDrawer/MiniPortfolio/Activity/Logos";
import { OrderContent } from "components/AccountDrawer/MiniPortfolio/Activity/OffchainActivityModal";
import { TradeSummary } from "components/ConfirmSwapModal/TradeSummary";
import { slideInAnimation, slideOutAnimation } from "components/ConfirmSwapModal/animations";
import Column, { ColumnCenter } from "components/deprecated/Column";
import Row from "components/deprecated/Row";
import { useAccount } from "hooks/useAccount";
import { useSwapTransactionStatus } from "hooks/useSwapCallback";
import { useUnmountingAnimation } from "hooks/useUnmountingAnimation";
import styled, { css } from "lib/styled-components";
import { useMemo, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { TradeFillType } from "state/routing/types";
import { isLimitTrade, isUniswapXTradeType } from "state/routing/utils";
import { useOrder } from "state/signatures/hooks";
import { useIsTransactionConfirmed } from "state/transactions/hooks";
import { AnimationType } from "theme/components/FadePresence";
import { ExternalLink } from "theme/components/Links";
import { ThemedText } from "theme/components/text";
import { UniswapXOrderStatus } from "types/uniswapx";
import { uniswapUrls } from "uniswap/src/constants/urls";
import { TransactionStatus } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { UniverseChainId } from "uniswap/src/features/chains/types";
import { ExplorerDataType, getExplorerLink } from "uniswap/src/utils/linking";
const Container = styled(ColumnCenter)`
  margin: 48px 0 8px;
`;
const HeaderContainer = styled(ColumnCenter)`
  ${({ $disabled }) => $disabled && `opacity: 0.5;`}
  padding: 0 32px;
  overflow: visible;
`;
const AnimationWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 72px;
  display: flex;
  flex-grow: 1;
`;
const StepTitleAnimationContainer = styled(Column)`
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  transition: display ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
  ${({ disableEntranceAnimation }) => !disableEntranceAnimation && css`
      ${slideInAnimation}
    `}

  &.${AnimationType.EXITING} {
    ${slideOutAnimation}
  }
`;
function getTitle({
  t,
  trade,
  swapPending,
  swapConfirmed
}) {
  if (isLimitTrade(trade)) {
    if (swapPending) {
      return t("swap.limitSubmitted");
    }
    if (swapConfirmed) {
      return t("swap.limitFilled");
    }
    return t("swap.confirmLimit");
  }
  if (swapPending) {
    return t("swap.submitted");
  }
  if (swapConfirmed) {
    return t("swap.success");
  }
  return t("swap.confirmSwap");
}
export function Pending({
  trade,
  swapResult,
  wrapTxHash,
  tokenApprovalPending = false,
  revocationPending = false
}) {
  const initialTrade = useRef(trade).current;
  const { chainId } = useAccount();
  const { t } = useTranslation();
  const swapStatus = useSwapTransactionStatus(swapResult);
  const uniswapXOrder = useOrder(isUniswapXTradeType(swapResult?.type) ? swapResult.response.orderHash : "");
  const limitPlaced = isLimitTrade(initialTrade) && uniswapXOrder?.status === UniswapXOrderStatus.OPEN;
  const swapConfirmed = swapStatus === TransactionStatus.Confirmed || uniswapXOrder?.status === UniswapXOrderStatus.FILLED;
  const wrapConfirmed = useIsTransactionConfirmed(wrapTxHash);
  const swapPending = swapResult !== void 0 && !swapConfirmed;
  const wrapPending = wrapTxHash != void 0 && !wrapConfirmed;
  const transactionPending = revocationPending || tokenApprovalPending || wrapPending || swapPending;
  const showSubmitted = swapPending && !swapConfirmed && chainId === UniverseChainId.Mainnet;
  const showSuccess = swapConfirmed || chainId !== UniverseChainId.Mainnet && swapPending;
  const currentStepContainerRef = useRef(null);
  useUnmountingAnimation(currentStepContainerRef, () => AnimationType.EXITING);
  const explorerLink = useMemo(() => {
    let txHash;
    if (swapResult && swapResult.type === TradeFillType.Classic) {
      txHash = swapResult.response.hash;
    } else if (uniswapXOrder && uniswapXOrder.status === UniswapXOrderStatus.FILLED) {
      txHash = uniswapXOrder.txHash;
    } else {
      return void 0;
    }
    return getExplorerLink(chainId || UniverseChainId.Mainnet, txHash, ExplorerDataType.TRANSACTION);
  }, [chainId, swapResult, uniswapXOrder]);
  if (uniswapXOrder && uniswapXOrder.status !== UniswapXOrderStatus.OPEN && uniswapXOrder.status !== UniswapXOrderStatus.FILLED) {
    return <OrderContent order={uniswapXOrder} />;
  }
  return <Container gap="lg"><LogoContainer>{
    /* Shown only during the final step under "success" conditions, and scales in */
  }{showSuccess && <AnimatedEntranceConfirmationIcon />}{
    /* Shown only during the final step on mainnet, when the transaction is sent but pending confirmation */
  }{showSubmitted && <AnimatedEntranceSubmittedIcon />}{
    /* Scales in for any step that waits for an onchain transaction, while the transaction is pending */
  }{
    /* On the last step, appears while waiting for the transaction to be signed too */
  }{!showSuccess && !showSubmitted && <LoadingIndicatorOverlay />}</LogoContainer><HeaderContainer gap="md" $disabled={transactionPending && !limitPlaced}><AnimationWrapper><StepTitleAnimationContainer gap="md" ref={currentStepContainerRef} disableEntranceAnimation><ThemedText.SubHeader width="100%" textAlign="center" data-testid="pending-modal-content-title">{getTitle({ t, trade: initialTrade, swapPending, swapConfirmed })}</ThemedText.SubHeader>{initialTrade && <ThemedText.LabelSmall textAlign="center"><TradeSummary trade={initialTrade} /></ThemedText.LabelSmall>}</StepTitleAnimationContainer></AnimationWrapper>{
    /* Display while waiting for user to make final submission by confirming in wallet */
  }{!swapPending && !swapConfirmed && <Row justify="center" marginTop="32px" minHeight="24px"><ThemedText.BodySmall color="neutral2">{t("common.proceedInWallet")}</ThemedText.BodySmall></Row>}{
    /* Display while UniswapX order is still pending */
  }{uniswapXOrder && uniswapXOrder.status === UniswapXOrderStatus.OPEN && <Row justify="center" marginTop="32px" minHeight="24px"><ThemedText.BodySmall color="neutral2"><ExternalLink
    href={isLimitTrade(initialTrade) ? uniswapUrls.helpArticleUrls.limitsInfo : uniswapUrls.helpArticleUrls.uniswapXInfo}
  >{isLimitTrade(initialTrade) ? <Trans i18nKey="limits.learnMore" /> : <Trans i18nKey="uniswapX.learnMore" />}</ExternalLink></ThemedText.BodySmall></Row>}{
    /* Display after submitting Classic swap or after filling UniswapX order */
  }{explorerLink && <Row justify="center" marginTop="32px" minHeight="24px"><ThemedText.BodySmall color="neutral2"><ExternalLink href={explorerLink} color="neutral2"><Trans i18nKey="common.viewOnExplorer" /></ExternalLink></ThemedText.BodySmall></Row>}</HeaderContainer></Container>;
}

import { useMemo } from "react";
import { validColor } from "ui/src/theme";
import { opacify } from "ui/src/theme/color/utils";
export const useColorsFromTokenColor = (tokenColor) => {
  const { validTokenColor, lightTokenColor } = useMemo(() => {
    const validatedColor = validColor(tokenColor);
    return {
      validTokenColor: tokenColor ? validatedColor : void 0,
      lightTokenColor: tokenColor ? opacify(12, validatedColor) : void 0
    };
  }, [tokenColor]);
  return { validTokenColor, lightTokenColor };
};
